@import "../../../mixins.scss";

.carouselResultCardBus {
  //   margin-bottom: 50px;
  .loadingSkeletonResultCard {
    display: flex;
    gap: 10px;
    overflow-y: hidden;
    margin-right: -20px;
    margin-left: -20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;

    @include md {
      gap: 20px;
      overflow: hidden;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
    }

    .skeletonItemResultCard {
      //   width: 155px;
      width: calc(100%);

      @include md {
        width: calc(100%);
      }

      @include lg {
        // width: calc(50% - 16px);
        width: calc(100%);
      }

      flex-shrink: 0;

      .posterBlock {
        border-radius: 12px;
        width: 100%;
        aspect-ratio: 3/0.6;
        // margin-bottom: 22px;
      }

      .textBlock {
        display: flex;
        flex-direction: column;

        .title {
          width: 100%;
          height: 20px;
          margin-bottom: 10px;
        }

        .date {
          width: 75%;
          height: 20px;
        }
        .bottomHeading {
          width: 35%;
          height: 20px;
        }
      }
    }
  }
}

// for bigger filter

.carouselResultFilterBus {
  //   margin-bottom: 50px;
  .loadingSkeletonResultFilter {
    display: flex;
    gap: 10px;
    overflow-y: hidden;
    margin-right: -20px;
    margin-left: -20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;

    @include md {
      gap: 20px;
      overflow: hidden;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
    }

    .skeletonItemResultFilter {
      //   width: 155px;
      width: calc(100%);

      @include md {
        width: calc(100%);
      }

      @include lg {
        // width: calc(50% - 16px);
        width: calc(100%);
      }

      flex-shrink: 0;

      .posterBlock {
        border-radius: 12px;
        width: 100%;
        aspect-ratio: 1 / 2.5;
        // margin-bottom: 22px;
      }

      .textBlock {
        display: flex;
        flex-direction: column;

        .title {
          width: 100%;
          height: 20px;
          margin-bottom: 10px;
        }

        .date {
          width: 75%;
          height: 20px;
        }
        .bottomHeading {
          width: 35%;
          height: 20px;
        }
      }
    }
  }
}
