
.success-animation { margin:80px auto;}

.checkmarks {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
   margin: 0 auto;
}
.checkmarks__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
 
}

.checkmarks__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}



.tempBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 125px;
}


.tempBox h2 {
    font-weight: 700;
    color: #21325d;
}

/* .tempBox p {
   
    font-family: "Montserrat";
    font-weight: 300;
    color: var(--black2);
   
} */

.temp .para{
    font-size: 18px;
    font-weight: 700 !important;
    margin-bottom: 10px;
}

.tempBox button {
    padding: 10px 28px;
    border: 2px solid #d90429;
    background-color: #d90429;
    border-radius: 8px;
    color: white;
    font-weight: 700;
    transition: all 0.2s;
}

.tempBox button:hover {
    background-color: unset;
    color: #d90429;


}

.tempBox .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}


.ticketdetails{
    border: 1px solid rgba(128, 128, 128, 0.897);
    border-radius: 10px;

}

.ticket-details-destination{
    display: flex;
    gap: 12px;
    /* padding: 40px; */
}

.tickettex{
    font-size: 28px;
    font-weight: 500;
    color: var(--black2);

}

.tickettexvalue{
    font-size: 20px;
    font-weight: 500;
    color: var(--black2);

}

.tickettex1{
    font-size: 15px;
    font-weight: 600;
    color: var(--black4);

}

.datetimeticket{
    /* border-bottom: 1px solid rgba(128, 128, 128, 0.897); */
}