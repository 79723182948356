/* .flightLoading {
    width: 100vw !important;
    height: 100vh !important;
    z-index: 9999 !important;
    background-color: white !important;
    position: absolute;
} */


/* LoadingSpinner.css */
.paymentLoading {
    position: fixed;
    /* position: relative; */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999 !important;
    /* Ensure the spinner is above other content */
}

.flightLoading img {
    /* width: 500px; */
    /* margin-top: 100px; */
    /* Adjust the size of the loading GIF */
    height: 500px;
    /* Adjust the size of the loading GIF */
}