.bus_details_review {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    opacity: 1;
}

.gola_service {
    text-align: left;
    font: normal normal bold 34px/43px Quicksand !important;
    letter-spacing: 0px;
    color: #252525;
    opacity: 1;
}

.delhi_to_kanpur {
    text-align: left;
    font: normal normal bold 16px/20px Quicksand !important;
    letter-spacing: 0px;
    color: #FF8900;
    opacity: 1;
}

.seat_select {
    text-align: right;
    font: normal normal bold 12px/15px Quicksand !important;
    letter-spacing: 0px;
    color: #252525;
    opacity: 1;
}

.rating_txt {
    text-align: left;
    font: normal normal bold 14px/18px Quicksand;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.rating_txt {
    background: #1492E6 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    padding: 3px;
}

.rating_phone {
    text-align: left;
    font: normal normal bold 16px/20px Quicksand !important;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
}

.gps_enable {
    text-align: left;
    font: normal normal bold 16px/20px Quicksand !important;
    letter-spacing: 0px;
    color: #006FFF;
    opacity: 1;
}

.seat_num {
    text-align: right;
    font: normal normal bold 24px/30px Quicksand !important;
    letter-spacing: 0px;
    color: #252525;
    opacity: 1;
}

.seat_sleeper {
    text-align: left;
    font: normal normal bold 16px/20px Quicksand !important;
    letter-spacing: 0px;
    color: #252525;
    opacity: 1;
}

.view_politic {
    text-align: right;
    font: normal normal bold 14px/18px Quicksand !important;
    letter-spacing: 0px;
    color: #006FFF;
    opacity: 1;
}

.Left_side_con {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #00000029;
    border-radius: 10px;
    opacity: 1;
}

.time_date_text {
    font: normal normal bold 24px/30px Quicksand !important;
    letter-spacing: 0px;
    color: #252525;
    opacity: 1;
}

.time_dot {
    background: #006FFF 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 5px;
    width: 5px;
    border-radius: 10px;
}

.time_border {
    border: 1px dashed #006FFF;
    opacity: 1;
    width: 100px;
}

.con_direction {
    font: normal normal bold 16px/20px Quicksand !important;
    letter-spacing: 0px;
    color: #006FFF;
    opacity: 1;
}

.content_paragraph {
    font: normal normal bold 12px/15px Quicksand !important;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
}

.trav_details {
    text-align: left;
    font: normal normal bold 18px/23px Quicksand !important;
    letter-spacing: 0px;
    color: #252525;
    opacity: 1;
}

.booking_id_pnr {
    font: normal normal bold 16px/20px Quicksand !important;
    letter-spacing: 0px;
    color: #FF8900;
    opacity: 1;
}

.persion_details {
    font: normal normal bold 16px/20px Quicksand !important;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
}

.contact_details {
    font: normal normal bold 16px/20px Quicksand !important;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
}

.content_botttom {
    border: 1px dashed #006FFF;
    opacity: 1;
}

.download_btn {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
    width: 200px;
}

.btn_invoice {
    font: normal normal bold 14px/18px Quicksand !important;
    letter-spacing: 0px;
    color: #006FFF;
    opacity: 1;
}

.base_text {
    font: normal normal bold 14px/18px Quicksand !important;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
}

.base_price {
    font: normal normal bold 24px/30px Quicksand !important;
    letter-spacing: 0px;
    color: #FF8900;
    opacity: 1;
}

.sleeper_triangle {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 3px;
    opacity: 1;
    padding: 2px;
    width: 4px;
}

.sleeper_border {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 2px;
    opacity: 1;
    padding: 5px;
    width: 50px;
}
















/* busInfoComponent.css */

.bus-info-container {
    width: 1004px;
    height: 87px;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 24px;
    padding-bottom: 24px;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.info-left {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    display: inline-flex;
}

.info-right {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;
    display: inline-flex;
}

.company-name {
    color: #071C2C;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    word-wrap: break-word;
}

.bus-details {
    color: #BBBBBB;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    word-wrap: break-word;
}

.seat-number {
    color: #071C2C;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    word-wrap: break-word;
}

.view-policies {
    color: #d90429;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    word-wrap: break-word;
}

/* Media Query for Responsive Design */
@media (max-width: 768px) {
    .bus-info-container {
        width: 90%;
        padding: 16px;
    }

    .info-left,
    .info-right {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
    }
}