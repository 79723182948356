@import "../../../mixins.scss";
.flightFilterAirlines {
  /* display: flex; */
  padding: 14px 8px;
  // background: #eee;
  border-radius: 8px;
  margin-bottom: 10px;
  .flightFilterAirlinesScroll {
    overflow: hidden;
    overflow-y: scroll;
    height: 260px !important;
    width: 100%;
   > div {
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 3px;
    }
  }
}
.airtimeBackgrounditemsmall {
  background-color: #f1f1f1 !important;
  display: flex;
  justify-content: center;
  border: 1px solid transparent;
  // background-color: white;
  border-radius: 5px;
  padding: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }
  span{
    font-size: 13px;
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.flightAirlineSmalliImage {
  font-size: 13px;
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: transparent !important;
  mix-blend-mode: darken;
}
.airline-small-label-container {display: block;
position: relative;
padding-left: 35px;
/* margin-bottom: 12px; */
cursor: pointer;
-webkit-user-select: none;
user-select: none;
input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

}
.airline-small-label-container:has(input:checked) .airtimeBackgrounditemsmall {
    border: 1px solid #d90429 !important;
}
