@import "../../../mixins.scss";
.flightDetail-cnt {
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  color: var(--black4);

  background-color: #fbfbfb;
  // background-color: #fff7f7;
  @include md {
    gap: 16px;
    border: 0.5px solid lightgray;
    padding: 12px 16px;
    border-radius: 8px;
  }

  .layoverBox {
    border-top: 1px solid #d1d5db;
    border-bottom: 1px solid #d1d5db;
    padding: 10px 0px;

    p {
      font-size: 13px;
      font-weight: 500;
      text-align: center;
    }
  }

  .flightDetail-box-1 {
    display: flex;
    gap: 10px;
    align-items: center;
    .flightDetail-img {
      img {
        height: 35px;
        width: 35px;
      }
    }
    .flight-details-right {
      display: flex;
      gap: 10px;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      @include md {
        flex-direction: row;
        // width: unset;
      }
      .flight-details-right-img {
        display: none;
        @include md {
          position: relative;
          height: 100%;
          width: 70px;
          display: block;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 2;
        }
        .roundSpan {
          content: "";
          top: 0;
          width: 23px;
          height: 23px;
          position: absolute;
          border: 1px solid #d1d5db;
          border-radius: 100%;
          background-color: #fff;
        }

        .roundSpan2 {
          content: "";
          bottom: 0;
          width: 23px;
          height: 23px;
          position: absolute;
          border: 1px solid #d1d5db;
          background-color: #fff;
          border-radius: 100%;
        }
        .dottedSpan {
          height: 90px;
          border: 1px dashed #d1d5db;
        }
      }
      .flight-details-right-inner {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 5px;
        width: 100%;

        @include md {
          flex: 8;
        }

        .flight-details-right-inne-1 {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          white-space: nowrap;

          span {
            font-size: 12px;
            overflow: hidden; /* Hides any overflowing content */
            text-overflow: ellipsis;
            white-space: break-spaces;
            width: 90%;

            @include md {
              font-size: 14px;
              width: 100%;
            }
          }
          p {
            font-size: 12px;
            font-weight: 600;
            font-size: 12px;
            font-weight: 600;
            width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            @include md {
              font-size: 12px;
              width: 100%;
            }
          }
        }

        .detRightDetails {
          text-align: right;
          gap: 10px;

          span {
            font-size: 13px;
            font-weight: 500;
          }
        }

        .flight-details-right-inne-2 {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          flex: 1;

          span {
            font-size: 14px;
          }
          p {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
      .flight-details-right-inner-border {
        border: none;
        @include md {
          border-right: 1px solid #d1d5db;
        }
      }

      .flight-details-right-inner2 {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 5px;
        width: 100%;

        @include md {
          flex: 6;
        }

        .flight-details-right-inne-1 {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          white-space: nowrap;

          span {
            font-size: 12px;
            overflow: hidden; /* Hides any overflowing content */
            text-overflow: ellipsis;
            white-space: break-spaces;
            width: 90%;

            @include md {
              font-size: 14px;
              width: 100%;
            }
          }
          p {
            font-size: 12px;
            font-weight: 600;
            font-size: 12px;
            font-weight: 600;
            width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            @include md {
              font-size: 12px;
              width: 100%;
            }
          }
        }

        .detRightDetails {
          text-align: left;
          gap: 10px;

          @include md {
            text-align: right;
          }

          span {
            font-size: 13px;
            font-weight: 500;
          }
        }

        .flight-details-right-inne-2 {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          flex: 1;

          span {
            font-size: 14px;
          }
          p {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }

    .air-img {
      width: 30px;
      height: 30px;
      @include md {
        width: 40px;
        height: 40px;
      }
      img {
        width: 100%;
        height: 30px;
        object-fit: contain;
        @include md {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .book-now {
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: center;
    button {
      width: 100px;
      border: none;
      background-color: #e73c34;
      height: 35px;
      border-radius: 8px;
      color: #fff;
      padding: 4px 0px;
      font-size: 13px;
      font-weight: 500;
      cursor: pointer;
      @include md {
        width: 120px;
        height: 35px;
        border-radius: 10px;
        color: #fff;
        padding: 4px 0px;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}
