.filterBox {
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  /* border: 1px solid #c9caca; */
  gap: 20px;
  color: #21325D;
  background-color: #fff;

}

.filterBox p {
  color: #000;

  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0 !important;
}

.filterBox label {
  color: #21325D;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.filterBox select {
  padding: 4px !important;
}


.book-btn {
  background-color: #21325D;
  color: white;
  padding: 8px 12px;
}



.ModifySearch {
  padding: 10px;
  border-radius: 10px;
  /* background: white; */
  background-color: #fff;
  /* box-shadow: 0px 0px 24px -11px; */
}

.outerFilterBox {
  padding: 10px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}


.searchDestination b {
  text-transform: capitalize;
}


.css-k008qs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}





















.filteredNotFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.filteredNotFound h1 {
  color: #d90429;
  font-weight: 700;
  font-size: 22px;
}


.filteredNotFound img {
  width: 130px;
  height: 130px;
}





.exceptionalFlex {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
}


.hotelResultBoxSearch {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 8px;
  /* border: 1px solid lightgray; */
  background: #FFFBFB;
  margin-bottom: 17px;
  cursor: pointer;
  flex-wrap: nowrap;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


.highSelect {
  height: 50px;
  width: 180px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: white;
  text-align: center;
  border: 1px solid lightgray;
}

.hotelResultBoxSearch>div {
  display: flex;
  flex-direction: row;
}

.hotelResultBoxSearch p {
  margin-bottom: 0 !important;
}

.hotelImage {
  padding: 15px;
}

.hotelImage img {
  width: 250px;
  height: 180px;
}


.hotelResultDetails {
  display: flex;
  flex-direction: column;
  gap: 7px;
  justify-content: flex-start;
  padding: 8px;
}

.icon-box {
  display: flex;
  gap: 10px;
  flex-direction: row;
  overflow: hidden;
  overflow-x: scroll;

}

.icon-box>div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.priceBookHotel {
  /* flex: 28%; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  border-left: 1px solid lightgray;
}

.priceBookHotelOne {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  min-width: 135px;
  padding: 30px 0px;
}

.priceBookHotelOne span {
  color: #000;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.priceBookHotelOne p {
  color: #d90429;

  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.priceBookHotelOne h3 {
  color: #FFF;

  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 4px 12px;
  border-radius: 16px;
  background: #071C2C;
  display: inline-block;
}

.priceBookHotelOne h4 {
  color: #071C2C;

  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;

}

.priceBookHotelOne h4 svg {
  font-size: 13px !important;
}

.priceBookHotelOne b {
  color: #000;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.priceBookTwo button {
  padding: 11.28px 28.199px;
  border-radius: 5.64px;
  font-weight: 600;
  background: #21325D;
  color: #fff;
  border: 2px solid #21325D;
  transition: 0.3s all;
}

.priceBookTwo button:hover {
  background: transparent;
  color: #21325D;

}

.hotleTitle {
  color: #071C2C;
  font-family: Montserrat;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;



  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.css-i4bv87-MuiSvgIcon-root {
  font-size: 1.1rem !important;
}

.customize {
  color: #d90429;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hotAddress {
  color: #071C2C;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
  font-weight: 600;
  max-width: 266px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hotAddressLocation {
  color: #d90429;
  font-family: Montserrat;
  font-size: 14.1px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
  font-weight: 600;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-box p {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.hotelRating {}

.seeMore button {
  color: #0048FF;

  font-family: Montserrat;
  font-size: 16.919px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;
}

.packageResult .container-fluid {
  padding: 0px 20px !important;
}


/* style= {
        {
        width: 361,
         height: 642,
          background: 'white',
           borderRadius: 8, 
           border: '1px #5C85A4 solid', 
           padding: "10px"
    }
} */

.packResFilterBox {
  width: 100%;
  /* height: 100%; */
  /* margin: 10px 0px; */
  /* margin: 10px 0px; */
  border-radius: 8px;
  border: 1px solid #5C85A4;
  background: #FFF;
  padding-top: 8px;
  margin-bottom: 10px;

}

.icon-box-inner>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}





@media (max-width: 850px) {

  .packageImage {
    flex: 100% !important;
  }

  .hotelResultDetails {
    flex: 50% !important;
    width: 50%;
    margin-top: 20px;
  }

  .priceBookHotel {
    flex: 20% !important;
    margin-top: 20px;

  }

  .filterBox p {
    color: #000;
    font-family: Montserrat;
    font-size: 13px;

  }

  .filterBox {

    gap: 12px;

  }
}




.ratingStar img {
  margin-right: 8px;
}


.mainimgHotelSearch {
  position: relative;
}

.searchMainBoxAbs {
  position: absolute;
  bottom: 23px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: -1;
}

.HotelResultSearchBarBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background-color: white; */
  border-radius: 10px;
  /* padding: 15px; */
  /* backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background: #6b696973;
  border: 1px solid #ffffff2e;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.hotelResSurBox h3 {
  color: white;
  font-weight: 700;
  font-size: 28px;
}

.hotelResSurBox p {
  color: white;
  font-weight: 500;
  font-size: 16px;
}

.searchBarHotelFOrm input {
  padding: 14px;
  border-radius: 8px;
  border: none;
  min-width: 290px;
}




.ShowMoreHotel {
  color: #d90429;
}

.rotttt {
  transform: rotate(180deg);
}

.hotelResSurBox {
  text-align: left;
}



input[type="range"] {
  font-size: 1.5rem;
  width: 100%
}

input[type="range"] {
  color: #ef233c;
  --thumb-height: 0.825em;
  --track-height: 0.125em;
  --track-color: rgba(0, 0, 0, 0.2);
  --brightness-hover: 180%;
  --brightness-down: 80%;
  --clip-edges: 0.125em;
}

input[type="range"].win10-thumb {
  color: #2b2d42;

  --thumb-height: 1.375em;
  --thumb-width: 0.5em;
  --clip-edges: 0.0125em;
}


@media (prefers-color-scheme: dark) {
  html {
    background-color: #000;
  }

  html::before {
    background: radial-gradient(circle at center, #101112, #000);
  }

  input[type="range"] {
    color: #f07167;
    --track-color: rgba(255, 255, 255, 0.1);
  }

  input[type="range"].win10-thumb {
    color: #3a86ff;
  }
}

/* === range commons === */
input[type="range"] {
  position: relative;
  background: #fff0;
  overflow: hidden;
}

input[type="range"]:active {
  cursor: grabbing;
}

input[type="range"]:disabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: not-allowed;
}

/* === WebKit specific styles === */
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  position: relative;
}

input[type="range"]::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor;

  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(currentColor 0 0) scroll no-repeat left center / 50% calc(var(--track-height) + 1px);
  background-color: currentColor;
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));

  filter: brightness(100%);
  clip-path: polygon(100% -1px,
      var(--clip-edges) -1px,
      0 var(--clip-top),
      -100vmax var(--clip-top),
      -100vmax var(--clip-bottom),
      0 var(--clip-bottom),
      var(--clip-edges) 100%,
      var(--clip-further) var(--clip-further));
}

input[type="range"]:hover::-webkit-slider-thumb {
  filter: brightness(var(--brightness-hover));
  cursor: grab;
}

input[type="range"]:active::-webkit-slider-thumb {
  filter: brightness(var(--brightness-down));
  cursor: grabbing;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100% calc(var(--track-height) + 1px);
}

input[type="range"]:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}

/* === Firefox specific styles === */
input[type="range"],
input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb {
  appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
  background: #fff0;
}

input[type="range"]::-moz-range-thumb {
  background: currentColor;
  border: 0;
  width: var(--thumb-width, var(--thumb-height));
  border-radius: var(--thumb-width, var(--thumb-height));
  cursor: grab;
}

input[type="range"]:active::-moz-range-thumb {
  cursor: grabbing;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  background: var(--track-color);
}

input[type="range"]::-moz-range-progress {
  appearance: none;
  background: currentColor;
  transition-delay: 30ms;
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-progress {
  height: calc(var(--track-height) + 1px);
  border-radius: var(--track-height);
}

input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
  filter: brightness(100%);
}

input[type="range"]:hover::-moz-range-thumb,
input[type="range"]:hover::-moz-range-progress {
  filter: brightness(var(--brightness-hover));
}

input[type="range"]:active::-moz-range-thumb,
input[type="range"]:active::-moz-range-progress {
  filter: brightness(var(--brightness-down));
}

input[type="range"]:disabled::-moz-range-thumb {
  cursor: not-allowed;
}




.PackageDepartureMain {
  /* display: flex; */
  padding: 14px 8px;
  background: #eee;
  border-radius: 8px;
  margin-bottom: 10px;
}