.bookingMargin {
  margin-top: 170px;
  margin-bottom: 150px;
}

/* flight booking history css  */

.flightHisOuter {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-bottom: 10px;
}

.historyTop {
  padding: 20px 40px;
  background-color: #f9eded;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.historyTop>div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.historyTopLeft {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.historyTopLeft span {
  color: #071c2c;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.historyTopLeft svg {
  font-size: 23px;
  /* width: 1.5em; */
}

.historyStatus {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 14px;
  font-family: Montserrat;
}

.historyTopRight p,
.historyTopRight span {
  /* padding: 9px 18px; */
  border-radius: 6px;
  color: #071c2c;
  /* color: #fff; */
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.historyBottom {
  padding: 20px 40px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.historyBottom .historyBottomOne {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.historyBottom .historyBottomOne h3 {
  color: #071c2c;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.historyBottom .historyBottomOne span {
  color: #071c2c;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  font-size: 15px;
  font-family: Montserrat;
}

.historyBottom .historyBottomTwo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  align-items: flex-start;
}

.historyBottom .historyBottomTwo span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;

  color: #071c2c;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  font-size: 15px;
  font-family: Montserrat;
}

.historyBottom .historyBottomThree {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  align-items: flex-start;
}

.historyBottom .historyBottomThree button {
  padding: 4px 13px;
  border-radius: 7px;
  background-color: #071c2c;
  width: 100%;
  color: white;
  border: 2px solid #071c2c;
  font-weight: 600;
  font-size: 12px;
  font-family: Montserrat;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.historyBottom .historyBottomThree button:nth-child(2) {
  padding: 4px 13px;
  border-radius: 7px;
  /* background-image: linear-gradient(to right, #dc817e, #bb8197); */
  background-color: transparent;
  width: 100%;
  color: #d90429;
  /* border: 2px solid #dc817e; */
  border: 2px solid #d90429;
  font-weight: 600;
  font-size: 12px;
  font-family: Montserrat;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.2s;
}

.historyBottom .historyBottomThree button:nth-child(2):hover {
  padding: 4px 13px;
  border-radius: 7px;
  /* background-image: linear-gradient(to right, #dc817e, #bb8197); */
  background-color: #d90429;
  width: 100%;
  color: #fff;
  /* border: 2px solid #dc817e; */
  border: 2px solid #d90429;
  font-weight: 600;
  font-size: 12px;
  font-family: Montserrat;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/* disabled  */

.historyBottom .historyBottomThreeDisabled {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  align-items: flex-start;
}

.historyBottom .historyBottomThreeDisabled button {
  padding: 4px 13px;
  border-radius: 7px;
  background-color: #999;
  width: 100%;
  color: white;
  border: 2px solid #999;
  cursor: no-drop;
  font-weight: 600;
  font-size: 12px;
  font-family: Montserrat;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.historyBottom .historyBottomThreeDisabled button:nth-child(2) {
  padding: 4px 13px;
  border-radius: 7px;
  /* background-image: linear-gradient(to right, #dc817e, #bb8197); */
  background-color: transparent;
  width: 100%;
  color: #999;
  /* border: 2px solid #dc817e; */
  border: 2px solid #999;
  font-weight: 600;
  font-size: 12px;
  font-family: Montserrat;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.2s;
}

/* flight booking history css  */

/* change request  */

.modal-boxChange {
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  padding: 0px;
}

.modal-boxChange input[type="radio"] {
  display: unset !important;
}

.modal-boxChange .modal-header {
  padding: 25px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modal-boxChange .modal-header h2 {
  font-size: 20px;
  font-family: montserrat;
}

.modal-boxChange .input-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
}

.cancelForm {
  padding: 25px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.cancelForm p {
  font-weight: 600;
}

.cancelForm .input-text input[type="text"] {
  height: 60px !important;
}

/* .cancelForm label {
    font-weight: 600;
} */

.cancelForm .formGroup {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.historyCancelModal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 12px;
}

.historyCancelModal button {
  padding: 9px 13px;
  border-radius: 7px;
  background-color: #071c2c;
  width: 100%;
  color: white;
  border: 2px solid #071c2c;
  font-weight: 600;
  font-size: 12px;
  font-family: Montserrat;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.historyCancelModal button:nth-child(2) {
  border-radius: 7px;
  /* background-image: linear-gradient(to right, #dc817e, #bb8197); */
  background-color: #d90429;
  width: 100%;
  color: #fff;
  /* border: 2px solid #dc817e; */
  border: 2px solid #d90429;
  font-weight: 600;
  font-size: 12px;
  font-family: Montserrat;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.historyCancelModal button:nth-child(2):hover {
  border-radius: 7px;
  /* background-image: linear-gradient(to right, #dc817e, #bb8197); */
  background-color: transparent;
  width: 100%;
  color: #d90429;
  /* border: 2px solid #dc817e; */
  border: 2px solid #d90429;
  font-weight: 600;
  font-size: 12px;
  font-family: Montserrat;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.2s;
}

.confirmModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.confirmModal h3 {
  font-size: 27px;
  color: #d90429;
  font-weight: 800;
  font-family: montserrat;
  line-height: normal;
}

.confirmModal p {
  font-size: 17px;
  font-weight: 600;
  font-family: montserrat;
  line-height: normal;
}

.confirmModal span {
  font-weight: 600;
  font-size: 16px;
  font-family: montserrat;
}

.loaderBoxChangeReq {
  margin-top: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.response {
  text-align: center;
}

.modal-header-cancel {
  padding-left: 25px;
}

.bold-font {
  font-weight: 600;

}

.cancel-div {
  display: flex;
  flex-direction: row;
  gap: 25px;

}

.cancel-div .div1 {
  display: flex;
  flex-direction: column;


}

.cancel-div .div2 {
  text-align: center;
  display: flex;

}

.cancel-div .div3 {

  display: flex;
  flex-direction: column;

}


.historyBottomThree span {
  font-weight: 600;
  font-size: 20px;
  color: black;
}