.termTop {
    margin-top: 163px !important;
    margin-bottom: 20px;
}


.termTop h3 {
    text-align: center;
    padding-bottom: 14px;
    position: relative;
    display: inline-block;
    font-weight: 800;
    font-size: 25px;
    text-transform: uppercase;
    font-family: 'Montserrat';
}

.termTop>div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.termTop h3::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    bottom: 0;
    background-color: #d90429;
}


.termBottom {
    margin-top: 25px;
}


.termBottom p {
    font-size: 19px;
    font-weight: 500;
    text-align: justify;
    line-height: 30px;
    text-wrap: wrap;
}


.loaderBoxTc {
    margin-top: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}



.contactIconBox {
    padding: 20px;
    background-color: #21325d;
    border-radius: 8px;
    color: white;
    display: inline-block;
    /* font-size: 40px; */
}

.contactIconBox svg {
    width: 1.5em;
    height: 1.5em;
}


.termBottom .col-lg-4 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    padding: 10px;
}

.termBottom .col-lg-4 span {
    color: #d90429;
    font-weight: 600;
}

.termBottom .col-lg-4 p {
    font-size: 17px;
    font-weight: 700;
    text-align: left;
}