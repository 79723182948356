.login-page {
  width: 100%;
  height: 100vh;
  display: inline-block;
  display: flex;
  align-items: center;
}

/* .login-page {

    display: flex;
    justify-content: center;
    align-items: center;
} */

.form-right i {
  font-size: 100px;
}

.containers {
  max-width: 800px;
}

.leftLogin {
  position: relative;
  background-color: #21325d;
  border-radius: 15px;
}

.roundedCustom {
  border-radius: 15px;
}

.closeIncon {
  position: absolute;
  right: 14px;
  top: 14px;
  font-weight: 700;
  font-size: 30px;
  cursor: pointer;
}

#logInDisabled {
  background-color: #d904278c !important;
}

.btn-primaryLogin {
  width: 100% !important;
  background-color: #d90429 !important;
  border: 2px solid #d90429 !important;
  color: white !important;
}

.btn-primaryLogin:hover {
  border: 2px solid #d90429 !important;
  color: #d90429 !important;
  background-color: white !important;
}

.login-page input {
  font-weight: 600 !important;
}

.login-page label {
  font-weight: 600 !important;
}

.login-page i {
  color: #d90429 !important;
}

.logg {
  width: 100%;
  height: 100%;
  min-height: 250px;
}

.logg img {
  width: 100%;
  height: 100%;
  min-height: 250px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  /* opacity: 0.9; */
  border-radius: 15px 15px 0px 0px;
}

.login-page .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  height: 51px;
}

.input-group-absolute {
  position: absolute;
  bottom: -16px;
  font-size: 10px;
  color: #d90429;
}

.login-page button {
  height: 50px !important;
  font-weight: 700;
}

.account-btn {
  background-color: #fff;
  color: white;
  padding: 8px 20px;
  border-radius: 8px;
  background-color: #e73c34;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  cursor: pointer;
}

.account-btn:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.loginButt {
  display: flex;
  border-radius: 64px;
  border: 1px solid #e73c34;
  background: var(--white, #fff);
  color: #e73c34;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

@media only screen and (max-width: 710px) {
  .loginButt {
    display: flex;

    border: none !important;
  }
}

.loginButt:hover {
  background-position: right center;
  cursor: pointer;
  text-decoration: none;
}

.dropdownLogin {
  right: 0% !important;
  border-radius: 8px;
  top: 45px !important;
  background-color: white;
  color: #333;
  z-index: 2;
}

.dropdownLogin p {
  text-wrap: nowrap !important;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8) !important;
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
}

/* Add this to your CSS file or style block */
.network-error-modal {
  position: absolute;
  z-index: 99999999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -55px;
}

.swal-cancel-button {
  border: 2px solid rgb(217, 4, 41) !important;
  color: rgb(217, 4, 41) !important;
  /* Adjust the text color as needed */
  font-size: 18px !important;
  /* Adjust the font size as needed */

  font-weight: 600;
  background-color: #ffffff !important;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.swal-confirm-button {
  border: 2px solid #21325d !important;
  color: #21325d !important;
  /* Adjust the text color as needed */
  font-size: 18px !important;
  /* Adjust the font size as needed */

  font-weight: 600;
  background-color: #ffffff !important;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  outline: none !important;
}

/* Add this to your CSS file or style block */

/* Add this to your CSS file or style block */
.swal-title-text {
  color: black;
}

.swal-title-inner {
  display: flex;
  justify-content: space-between;
  border: 2px solid red;
  width: 100%;
  gap: 30px;
}

.swal-title-text {
  margin-left: 10px;
  color: black;
  font-weight: 400px;
}

/* Add shadow to SweetAlert */
.swal-popup.with-shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  /* Customize the shadow properties */
  background-color: #ffffff !important;
  width: 35% !important;
}

.invalidOptLogin {
  border-color: #dc3545 !important;
  /* color: #d90429 !important; */
}

.dropdownLogin p {
  font-size: 14px;
  font-weight: 600;
}