:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  --primary: #e73c34;
  --primaryHover: #bd251e;
  // --primaryHotel: #4ca6fe;
  // --primaryHoverHotel: #368be0;
  --primaryPurple: #4f46e5;
  --primaryPurpleHover: #3e37cc;
  --primaryHotel: #e73c34;
  --primaryHoverHotel: #bd251e;

  --primaryBlue: #071c2c;
  --black: #04152d;
  --black2: #111111;
  --black3: #222222;
  --black4: #333333c7;
  --black5: #444444;
  --borderDarkGray: #ddd;
  --borderLightGray: lightgray;
  --pureWhite: #fff;
  --white2: #f1f1f1;
  --black-lighter: #1c4b91;
  --black-light: #173d77;

  --lightPink: rgb(255, 236, 229);
  --lightGreen: rgb(220, 255, 232);
  --lightPurple: rgb(247, 227, 255);
  --darkPurple: rgb(236, 195, 252);
  --lightOrange: rgb(255, 251, 231);
  --border-radius: 10px;
  // --formGradient: linear-gradient(to top, #b0cefd 0.99%, #c2e9fb 100%);
  --formGradient: linear-gradient(to top, #fff 17%, #c2e9fb 100%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f1f1f1;
}

::-webkit-scrollbar {
  display: none;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #f2f2f2;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.5) 20%,
      rgba(#fff, 0.6) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.paddingCustom {
  padding-top: 0rem !important;
}

@media (min-width: 600px) {
  .paddingCustom {
    padding-top: 1.5rem !important;
  }
}

.holiBot {
  img {
    width: 100% !important;
  }
}
.holiBotMobile {
  span {
    width: 100% !important;
  }

  img {
    width: 100% !important;
  }
}

.paddingForMobileAndDesktop {
  padding-top: 70px !important;
}

@media (max-width: 600px) {
  :root {
    overflow-x: hidden;
  }

  .responsiveRangePicker {
    .ant-picker-panels {
      .ant-picker-date-panel {
        width: 175px !important;

        .ant-picker-header {
          height: 40px !important;
        }

        th {
          height: 21px !important;
          font-size: 11px !important;
        }

        td {
          font-size: 11px !important;
        }
      }
    }
  }
}

.shaan {
  cursor: pointer;
  background-color: var(--primary);
  color: var(--pureWhite);
  border-radius: var(--border-radius);
}
