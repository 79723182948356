@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.navbar {
  height: 80px;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.logoNav {
  max-width: 200px;
}

.logoNav img {
  width: 100%;
}

.navbar ul {
  margin-bottom: 0;
}

.menu-icon {
  display: none;
}

.nav-elements {}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

@media screen and (max-width: 930px) {
  .nav-elements ul {
    display: flex;
    justify-content: space-evenly;
    list-style-type: none;
  }

  .nav-elements ul li {
    margin-right: 19px !important;
  }
}

.nav-elements ul li {
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
  justify-content: center;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 30px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 800px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 80px;
    background-color: #f1edee;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    z-index: 9999999;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}


.visa-name{
  text-align: center;
  color: black;
}

@media (max-width: 800px) {
  .visa-name {
     text-align: left;
  }
}