@import "../../mixins.scss";

.downloadSection {
  .container {
    position: relative;
    padding: 5px 20px 25px 5px;
    .backdrop-img {
      width: 100%;
      height: 100%;
      position: absolute;
      //   position: absolute;
      top: 0;
      left: 0;
      opacity: 0.7;
      overflow: hidden;

      .lazy-load-image-background {
        //   position: absolute;
        // top: 0;
        // left: 0;
        // width: 100%;
        height: 100%;
        border-radius: 12px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 12px;
        }
      }
    }

    .download-app {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-top: 37px;

      @include md {
        padding-top: 0px;
      }

      h2 {
        font-size: 25px;
        font-weight: 600;
        z-index: 0;
        color: #071c2c;

        @include md {
          font-size: 36px;
        }
      }

      p {
        font-size: 15px;
        font-weight: 500;
        color: #071c2c;
        z-index: 0;
      }

      button {
        background-color: var(--primary) !important;
        color: white !important;
        height: 44px;

        &:hover {
          border-color: var(--primaryHover) !important;
          background-color: var(--primaryHover);
        }
      }

      .appLink {
        display: flex;
        flex-direction: column;
        gap: 6px;
        z-index: 0;
        input {
          border: 1px solid #ddd !important;
          padding: 0.675rem 0.75rem;
        }
        input:focus {
          border: 1px solid #ddd !important;
        }
      }

      .appButton {
        display: flex;
        justify-content: center;

        @include md {
          display: unset !important;
        }
      }
    }

    .col-lg-6 {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .downloadImg {
      width: 90%;
      .lazy-load-image-background {
        //   position: absolute;
        // top: 0;
        // left: 0;
        // width: 100%;
        height: 100%;
        border-radius: 12px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 12px;
        }
      }
    }
  }
}
