.priceSummary {
    border-radius: 8px;
    /* border: 1px solid #5C85A4; */
    background: #FFF;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 16px;
}

.headFlight {
    border-radius: 4px 4px 0px 0px;
    /* background: #B8CCFF; */
    /* background: #d90429; */
    background: #FFFBFB;

    padding: 10px;
    text-align: center;
}

.headFlight span {
    /* color: #fff; */
    color: #E73C34;
    font-family: 'Montserrat';
    font-size: 18px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.hotName {

    margin: 12px 0px;
}

.hotName p {
    color: #000;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px;
}

.priceChart {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 5px;
}

p {
    margin-bottom: 0 !important;
}



.priceChart>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.priceChart>div:not(:last-child) {
    border-bottom: 1px solid #ddd;
}

.totCOmmFlight {
    display: flex;
    flex-direction: column;
    background: #F9EDED;
    gap: 5px;
    padding: 10px;
    margin-bottom: 15px;
}


.totCOmmFlight>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}
.totCOmmFlightDiv{
    display:flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
}

.totCOmmFlight>div span {
    font-size: 13px;
}

.totCOmmFlight>div p {
    font-size: 13px;
}



.TotGstFlight {
    display: flex;
    flex-direction: column;
    /* background: #B8CCFF; */
    background: #d90429;
    padding: 10px;
    gap: 5px;
}

.TotGstFlight>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white !important;
}




.text-bold {
    font-weight: 600;
}

.applycoupen-button {
    width: 100%;
    margin-top: 12px;
    padding: 10px;
    border: none;
    color: #FFF;
    font-weight: bold;
    font-size: 20px;
    background: rgb(237, 118, 119);
    background-image: linear-gradient(to right, #d90429 0%, #EA384D 51%, #d90429 100%);
    border-radius: 12px;
}

.inputfieldtext {
    margin-top: 12px;
    padding: 12px;
    border-radius: 12px;
    width: 100% !important;
    border: 1px solid black !important;
    border: none;
}

/* .applycoupenbuttontext{ */
/* border: 1px solid black; */
/* box-shadow: 5px 5px 2px grey;
    border-radius: 12px; */
/* } */

.viewallcoupen {
    color: rgb(118, 118, 166);
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    align-items: end;
}

.applycoupen-button1 {
    width: 100%;
    margin-top: 12px;
    padding: 10px;
    border: none;
    color: #FFF;
    font-weight: bold;
    font-size: 20px;
    background: rgb(237, 118, 119);
    background-image: linear-gradient(to right, #d90429 0%, #EA384D 51%, #d90429 100%);
    border-radius: 12px;
}


@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation: rotate 1s linear infinite;
    margin: 0 auto;
  }
  

 
  .error-message1{
    color: red;
    font-weight: bold;
    font-size: 15px;
}











/* /////////////////////////////////////////////////////////////////////// */

.priceSummary-new{
    /* .priceSummary { */
        border-radius: 8px;

        border: 1px solid #d3d3d3;
        background: #FFF;
        /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
        padding: 16px;
    /* } */
}


.headFlight-new {
    border-radius: 4px 4px 0px 0px;
    /* background: #B8CCFF; */
    /* background: #d90429; */
    background: #FFFBFB;
    /* background: #FFFBFB; */
    padding: 10px;
    text-align: center;
}

.headFlight-new span {
    /* color: #fff; */
    color: #E73C34;
    font-family: 'Montserrat';
    font-size: 18px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.totCOmmFlight-new {
    display: flex;
    flex-direction: column;
    background: #FFFBFB;
    gap: 5px;
    padding: 10px;
    margin-bottom: 15px;
}


.totCOmmFlight-new>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}
.totCOmmFlightDiv{
    display:flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
}

.totCOmmFlight-new>div span {
    font-size: 13px;
}

.totCOmmFlight-new>div p {
    font-size: 13px;
}

.TotGstFlight-new{
    display: flex;
    flex-direction: column;
    /* background: #B8CCFF; */
    /* background: #FFFBFB; */
    padding: 10px;
    gap: 5px;
}

.TotGstFlight-new>div {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
    color: #333333 !important;

}


.departurelocation-value{
    color: var(--black2) !important;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0 !important;
}


.checkInCheckOutBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 8px;

    .checkInInnerBoxOne {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      > div {
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid lightgray;
        display: flex;
        flex-direction: column;
        gap: 6px;
        justify-content: flex-start;
        align-items: flex-start;

        &:nth-child(1) {
          border-right: 1px solid lightgray;
        }
      }

      p {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0 !important;
        color: var(--black4) !important;
        font-family: "Montserrat";
      }
      h5 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0 !important;
        color: var(--black2) !important;
        font-family: "Montserrat";
      }
      h2 {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0 !important;
        color: var(--black4) !important;
        font-family: "Montserrat";
      }
    }
    .checkInInnerBoxTwo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;

        p {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0 !important;
          color: var(--black4) !important;
          font-family: "Montserrat";
        }
        h5 {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0 !important;
          color: var(--black4) !important;
          font-family: "Montserrat";
        }
      }
}

.bookleftdetail{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.textcolor{
font-size: 15px;
            font-weight: 600;
            margin-bottom: 0 !important;
            color: var(--black2) !important;
            font-family: "Montserrat";
}

.textcolor1{
font-size: 13px;
            font-weight: 500;
            margin-bottom: 0 !important;
            color: var(--black4) ;
            font-family: "Montserrat";
}