@import "../../mixins.scss";

.heroBannerFlight {
  width: 100%;
  height: 507px;
  background-color: black;
  // display: flex;
  align-items: center;
  position: relative;
  @include md {
    height: 90vh;
  }

  .backdrop-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    overflow: hidden;

    .lazy-load-image-background {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .opacity-layer {
    width: 100%;
    height: 135px;

    @include md {
      height: 250px;
    }
    // background: linear-gradient(
    //     180deg,
    //     rgba(4,21,45,0) 0%,
    //     #04152d 79.17%
    // );
    background: linear-gradient(
      180deg,
      rgba(181, 182, 184, 0) 0%,
      #f8f8f8 79.17%
    );
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .heroBannerContent {
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    color: black;
    position: relative;
    margin-top: -100px;
    margin: -79px auto 0 auto;
    padding: 0;

    @include md {
      margin: -242px auto 0 auto;
    }

    .transParentBG {
      padding-top: 30px;
      @include md {
        background: rgba(255, 255, 255, 0.55);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
      }
    }
    .transParentBGFlight {
      @include md {
        background: rgba(255, 255, 255, 0.55);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
      }
    }

    .headingTitle {
      font-size: 30px;
      font-weight: 700;
      color: white;
      text-align: center;
      margin-bottom: 14px;
      display: none;
      @include md {
        margin-bottom: 22px;
        display: unset;
        // font-size: 26px;
        font-style: 90px;
      }
    }
    .subTitle {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 40px;
      @include md {
        font-size: 24px;
      }
    }
    .searchInput {
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      border-radius: 10px;

      .ant-select {
        width: calc(100% - 100px);
        height: 50px;
        background-color: white;
        outline: 0;
        border: 0;
        border-radius: 10px 0 0 10px;
        padding: 0px 2px;
        font-size: 14px;
        @include md {
          width: calc(100% - 150px);
          height: 60px;
          font-size: 20px;
          padding: 0px 2px;
        }
      }
      .ant-select-selector {
        border: none !important;
        outline: none !important;
        font-weight: 500 !important;
        font-size: 17px !important;
        box-shadow: unset !important;
        @include md {
          font-weight: 600 !important;
        }
      }
      .ant-select-selection-item {
        text-align: left !important;
        font-size: 17px !important;
        font-weight: 500 !important;
        @include md {
          font-weight: 600 !important;
        }
      }
      .ant-select-selection-placeholder {
        text-align: left !important;
      }
      .ant-select-focused {
        box-shadow: unset !important;
      }

      .ant-select-dropdown {
        font-size: 17px !important;
        font-weight: 600 !important;
      }

      .ant-select-item {
        font-size: 17px !important;
        font-weight: 500 !important;
        @include md {
          font-weight: 600 !important;
        }
      }
      input {
        // width: calc(100% - 100px);
        // height: 50px;
        // background-color: white;
        // outline: 0;
        // border: 0;
        // border-radius: 30px 0 0 30px;
        // padding: 0px 15px;
        // font-size: 14px;
        // @include md {
        //   width: calc(100% - 150px);
        //   height: 60px;
        //   font-size: 20px;
        //   padding: 0px 30px;
        // }
      }
      button {
        width: 100px;
        height: 50px;
        background-color: var(--primary);
        color: white;
        outline: 0;
        border: 0;
        border-radius: 0px 10px 10px 0;
        font-size: 16px;
        cursor: pointer;
        @include md {
          width: 150px;
          height: 60px;
          font-size: 18px;
        }
      }
    }

    .tabBox {
      display: flex;
      gap: 15px;
      align-items: center;
      margin: 20px 0px;

      > div {
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        border-bottom: 1px solid transparent;
        color: white;

        @include md {
          font-weight: 600;
          color: black;
        }
      }

      .selectedTabs {
        color: #e73c34;
        border-bottom: 1px solid #e73c34;
      }
    }
  }
}

.paddHotWhyChoose {
  padding-top: 25px !important;
  border-radius: 10px;
  margin-bottom: 40px;
  overflow-x: hidden;

  h2 {
    font-size: 20px !important;
    font-weight: 700 !important;
    text-align: center !important;
    margin-bottom: 33px !important;

    @include md {
      font-size: 35px;
    }
  }
}
