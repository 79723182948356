.form {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
    padding: 5px 0px;
    margin-top: 5px;
}

.leftsection {
    margin: 0;
    padding-bottom: 7px !important;
}

.top_heading {

    font: normal normal bold 24px/30px Quicksand !important;
    letter-spacing: 0px !important;
    color: #252525 !important;
    opacity: 1 !important;
    padding-top: 24px !important;
    background: rgba(255, 255, 255, 0.95);
}

.ticket_details {
    /* margin: auto !important; */
    margin-top: 18px !important;
    /* width: 914px !important; */
    /* margin: 20px 10px; */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 10px #00000036 !important;
    border-radius: 20px !important;
    opacity: 1 !important;
}

.para_head {
    text-align: left !important;
    font: normal normal bold 20px/25px Quicksand !important;
    letter-spacing: 0px !important;
    color: #252525 !important;

}

.deals {
    display: flex !important;
    /* justify-content: center !important; */
    /* text-align: center !important; */
    border-radius: 8px;
    border: 0.5px solid #071C2C;
    background: #F9EDED;
    padding: 10px;
}

.deal_const {
    display: block !important;
    /* padding: 25px !important; */
    /* text-align: start !important; */

}

.txt_m {

    letter-spacing: 0px !important;
    color: #071C2C !important;
    opacity: 1 !important;
    /* margin-left: 30px !important; */
}

.txt_small {
    letter-spacing: 0px !important;
    font-size: 12px !important;
    line-height: 1;
    font-weight: 500 !important;
    color: #707070 !important;
    /* margin-left: 30px !important; */
    margin-top: 10px !important;
}

.txt_small1 {
    letter-spacing: 0px !important;
    font-size: 12px !important;
    line-height: 1;
    font-weight: 500 !important;
    color: #707070 !important;
    /* margin-left: 30px !important; */
    margin-top: 0px !important;
}

.time_up {

    letter-spacing: 0px !important;
    color: #071C2C !important;
    opacity: 1 !important;
}

.time_up1 {
    color: #071C2C;
    font-family: Montserrat !important;
    font-size: 28px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
}

.guidline {
    text-align: left !important;
    font: normal normal bold 16px/2px Quicksand !important;
    letter-spacing: 0px !important;
    color: #071C2C !important;
    opacity: 1 !important;
    margin-left: 22px !important;
    margin-top: 12px !important;
}

.list_item1 {

    color: #071C2C;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.main_he {
    font: normal normal bold 16px Quicksand !important;
    color: black !important;
    display: flex;
    justify-content: center;
}

.main_m {
    font: normal normal bold 12px Quicksand !important;
    color: #707070 !important;
    display: flex;
    justify-content: center;
}

.fare_summery {
    height: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    margin: 10px;
}



/* travellers form css*/
.Top_txt {
    font-size: 16px;
    font-weight: bold;
    color: #071C2C !important;
}

.topest_field {
    height: 50px;
    position: relative;
    width: 100%;

}

.topest_field input {
    width: 100%;
    height: 100%;
    padding-left: 25px;
    border-radius: 10px;
    border: 3px solid #70707069;
}

.label_field {
    color: #4E4C4C;
    font-size: 13px;
    font-weight: 700;
    font-family: 'Quicksand', sans-serif;
    left: 10px;
    line-height: 14px;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    top: 24px;
    transform: translateY(-30px) translateX(10px);
    background: #fff;
    padding: 0px 8px;
}

.select_class {
    width: 100%;
    height: 50px;
    border: 3px solid #70707069;
    border-radius: 10px;
}



.seat_section {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
}

.Inner_seat_section {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000036;
    border-radius: 20px;
    padding: 20px;
    margin-top: 15px;
}

.seat_Top_heading {
    text-align: center;
    font: normal normal bold 24px/30px Quicksand !important;
    letter-spacing: 0px;
    color: #252525;
}

.seat_heading {
    font: normal normal bold 20px/25px Quicksand !important;
    letter-spacing: 0px;
    color: #252525;
}



.booknowp {
    height: 1300px;
    width: 500px;
    padding-left: 20px;
}

.bgimg {
    overflow: scroll;
    height: 100%;
}

.sheetbook {
    margin-top: -1000px;
    margin-left: 27px;
    display: flex;
    justify-content: center;
}

.cart {
    margin-left: 10px;
}



/* ========== */
.toggle_button {
    background-color: white;
    border: none;
    width: 30px;
    height: 33px;
    border-radius: 5px;
    margin: 2px !important;
    cursor: pointer;

}

.toggle_close {
    background-color: #FF8900;
    border: none;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    /* margin: 2px !important; */
    cursor: pointer;
}

.toggle_button {
    padding: 8px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    border: none;
}

/* end */

.additional_text {

    color: #d90429;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}