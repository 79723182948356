.priceSummaryHotel {
    border-radius: 8px;
    border: 1px solid lightgray;
    background: #FFF;
    padding: 13px;
}

.priceSummaryHotel .head {
    border-radius: 4px 4px 0px 0px;
    background: #F9EDED;
    background: #d90429;
    padding: 10px;
}

.head span {
    color: #fff;
    font-family: 'Montserrat';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.hotName {

    margin: 12px 0px;
}

.hotName p {
    color: #000;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px;
}

.priceChart {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 5px;
}

p {
    margin-bottom: 0 !important;
}



.priceChart>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.priceChart>div:not(:last-child) {
    border-bottom: 1px solid #ddd;
}

.totCOmm {
    display: flex;
    flex-direction: column;
    background: #DFE6F7;
    gap: 5px;
    padding: 10px;
    margin-bottom: 15px;
}


.totCOmm>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}



.TotGst {
    display: flex;
    flex-direction: column;
    background: #F9EDED;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 0.5px solid lightgray;
    border-radius: 0px 0px 3px 3px;
    padding: 10px;
    gap: 5px;
}

.TotGst>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}




.text-bold {
    font-weight: 600;
}