.blogBox {
    padding: 16px 30px 78px;
}

.partner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.partner-container h3 {
    text-align: center !important;
    width: 73% !important;
    padding-bottom: 10px !important;
    font-family: Montserrat;
    font-weight: 800;
    font-size: 1.7rem;
    margin-bottom: 30px;
}

.blog-slide {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 10px;
    width: 100% !important;
    padding: 10px;
    margin-right: 10px !important;
    flex-direction: column;

}

.blogMainBox {
    padding: 10px;
    background-color: white;
    border-radius: 8px;
}


.imgBoxBlog {

    height: 230px;
    width: 100%;
    position: relative;

}

.ibbabs {
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: rgb(3, 156, 3);
    padding: 5px 10px;
    font-size: 10px;
    color: white;
    font-weight: 500;
    border-radius: 5px;
}

.blogBack {
    background-color: antiquewhite;
}

.imgBoxContentBlog {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 13px;
}

.imgBoxContentBlog span {
    font-size: 1.2rem;
    font-weight: 700;
    color: black;
}

.imgBoxContentBlog p {
    font-size: 1rem;
    color: black;
    font-weight: 500;
}

.imgBoxBlog img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 5px;

}



.locDate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.locDataInner {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

}

.locDataInner span {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #FF890026;
    display: flex;
    justify-content: center;
    align-items: center;
}

.locDataInner span svg {
    fill: #FF8900;
    position: relative;
}

/* .blogContent {
    padding: 10px;
} */

.blogContent h2 {
    font-size: 1.1rem;
    font-weight: 700;
    font-family: Montserrat;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}

.blogContent button {
    padding: 8px 10px;
    border-radius: 23px;
    /* background: linear-gradient(180deg, #d90429, #d904279c); */
    color: #FFF;
    font-family: 'Montserrat';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    transition: 0.5s;
    background-size: 200% auto;
    background-image: linear-gradient(to right, #d90429 0%, #EA384D 51%, #d90429 100%);
}

.features-content p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blogContent p {
    font-size: 0.9rem;
    font-weight: 500;
    font-family: Montserrat;

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blogBox .slick-prev,
.blogBox .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 34px;
    height: 34px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}


.blogBox .slick-prev:before,
.blogBox .slick-next:before {
    font-family: 'slick';
    font-size: 30px;

    line-height: 1;
    opacity: 1;
    color: #E73C34;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


@media(max-width:650px) {
    .blog-slide img {
        width: 100%;
    }
}

@media screen and (min-width: 1000px) and (max-width:1200px) {
    .imgBoxBlog {
        height: 230px;
        width: 100%;
        position: relative;
    }
}



.BlogheadingContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 30px 0;
}

.BlogheadingContainer h3 {
    font-family: Montserrat;
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 17px;
    position: relative;
}

.BlogheadingContainer h3::before {

    background-color: #e73c34;
    border-radius: 0 10px 0 10px;
    bottom: 0;
    content: "";
    height: 5px;
    left: 50%;
    position: absolute;
    transform: translate(-50%);
    width: 70px;
}



/* single blog design  */

.singleBlogHeading h1 {
    font-size: 2.1rem;
    font-weight: 600;
    font-family: Montserrat;
}


/* single blog design  */