.filter-new-data {
  display: flex;
  justify-content: space-between !important;
  /* gap: 168px; */
  width:100%;
}

.filter-name {
  display: flex;
  flex-direction: row;
}
.bookBottomTwo2{
  width: 30px !important;
  height: 85px !important;
}
.filterpart-1 {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--8, 32px);
  border-radius: 16px;
  border: 1px solid var(--gray-300, #d1d5db);
  background: var(--white, #fff);
}

.stop-filter-new {
  display: flex;
  flex-direction: column;
}

.stop-filter-select {
  display: flex;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d1d5db);
  background: var(--gray-50, #f9fafb);
  padding: var(--3, 12px) 8px;
  align-items: center;
  gap: 3px;
}

.select-filter-heading {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.filter-checkbox-timimg {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.checkbox-options-new {
  gap: 12px;
  color: #000;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.slider-value-new {
  color: #071c2c;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.newdata-searchlist {
  display: flex;
  justify-content: "space-between" !important;
  align-items: "center" !important;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d1d5db);
  background: var(--white, #fff);
  padding: 12px 16px;

  gap: 16px;
}

.flight-number-new {
  color: rgba(231, 60, 52, 0.56);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
}

.flight-name-new {
  color: #071c2c;
  /* text-align: center; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 28.8px */
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 100px; */
  
}
.refund_text{
  font-size: 12px;
  text-transform: lowercase;
  width: 100%;
  text-align: center;
  color: #e73c34;
}
.refund_text::first-letter {
  text-transform: uppercase;
}
.flex-center{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flight-time-new {
  text-align: center;
}
.flight-date-new {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}

.flight-time-new h4 {
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.flight-view-button-new {
  color: #e73c34;
  border: none;
  background-color: transparent; /* Change 'none' to 'transparent' */
  font-size: 14.1px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer; /* Optionally, add cursor style if needed */
  /* Add any additional styles here */
}
.line {
  /* height: 12px; */
  /* margin-bottom: 6px; */
  border-radius: 2px;
  background: rgba(130, 130, 130, 0.2);
  /* background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(8%, rgba(130, 130, 130, 0.2)),
      color-stop(18%, rgba(130, 130, 130, 0.3)),
      color-stop(33%, rgba(130, 130, 130, 0.2))
    ); */
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-color: #f6f7ff;
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.modalContainerInner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  background: rgb(255, 255, 255);
  padding: 10px;
}
.modalSearchResHeader {
  border-bottom: 1px solid gray;
  height: 65px;
  display: flex;
  align-items: end;
  justify-content: space-between;
  font-size: 20px;
  width: 100%;
}
.modalSearchResHeaderDiv {
}
.modalSearchResHeaderDiv > div > h1 {
  font-size: 16px;
  font-size: 700;
}
.searchResModalBody {
  width: 100%;
    height: 95vh;
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow-y: scroll;
    /* flex: 1; */
    padding-bottom: 66px;
}
.searchResModalBodyRow {
  display: flex;
  gap: 5px;
  align-items: center;
}
.searchResModalBodyInfo {
  padding: 20px 0px;
}
.searchResHeaderCross {
  height: 100%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.searchResModalBodyRowFont1 {
  font-size: 20px;
  font-weight: 500;
}
.searchResModalBodyRowFont2 {
  font-size: 14px;
  font-weight: 500;
}
.searchResModalBodyMap {
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.searchResModalBodyMapItem{
  display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
}
.searchResModalBodyMapItemCon{
  display: flex;
    /* height: 100px; */
    justify-content: space-between;
    flex-direction:column;
    gap:10px;
}
.searchResModalBodyRowFont2 > :first-child {
  font-weight: 600;
}
.modalheaderDiverder {
  background: #e72d40;
  padding: 2px;
  border-radius: 4px;
}
.flightLayoverOuter {
  text-align: center;
  position: relative;
  margin: 25px 0;
}
.flightLayover {
  border-radius: 4px;
  background-color: #f4f4f4;
  padding: 5px 10px;
  display: inline-block;
  position: relative;
  color: #ff4d2a;
  font-size: calc(var(--font-scale, 1)*12px);
}
 .flightLayover::before {
  position: absolute;
  background: #aa3535;
  height: 1px;
  content: "";
  width: 100px;
  top: 49%;
  left: -99px;
}
 .flightLayover::after {
  position: absolute;
  background: #aa3535;
  height: 1px;
  content: "";
  width: 100px;
  top: 49%;
  right: -99px;
}
.bookBottomThreeP{
  display: flex;
  flex-direction: column;
}
.modalSelectFilghtFooter{
  box-shadow: rgba(0, 0, 0, 0.15) 0px -2px 4px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 10px;
    gap: 10px;
}

.modalSearchflightBtn{
  background: rgb(231, 60, 52);
  color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 7px 0px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  height: 35px;
  cursor: pointer;
  font-size: 18px;
}

.singleFlightBox {
  display: flex;
  flex-direction: row;
  /* margin: 0px 0px 10px 0; */
  border-radius: 3px;
  /* border: 1px solid #9e9e9e; */
  background-color: #fffbfb;
  /* padding: 15px 10px; */
  justify-content: space-between;
  /* width: 100%; */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.mobileflexDesign {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0px;
  border-radius: 8px;
  /* border: 1px solid #9e9e9e; */
  background-color: #fffbfb;
  padding: 15px 10px;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.mandaField {
  border-radius: 4px;
  padding: 2px 7px;
  background-color: rgb(255, 237, 209);
  font-weight: 600;
  font-size: 14px !important;
  margin-top: 10px;
}

.singleFlightBox:not(:first-child) {
  display: flex;
  flex-direction: row;
  /* margin: 10px 0px; */
  border-radius: 8px;
  /* border: 1px solid #9e9e9e; */
  background-color: #fffbfb;
  /* padding: 15px 10px; */
  justify-content: space-between;
  width: 100%;
}

.singleFlightBox>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.singleFlightBoxOne img {
  width: 40px;
}

.singleFlightBoxOne span {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.singleFlightBoxOne p {
  color: #B03737;
  font-family: 'Montserrat';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.singleFlightBoxTwo span {
  color: #000;
  font-family: 'Montserrat';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.singleFlightBoxTwo p {
  color: #000;
  font-family: 'Montserrat';
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.singleFlightBoxTwo h5 {
  color: #000;
  font-family: 'Montserrat';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.singleFlightBoxThree h4 {
  color: #000;

  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.singleFlightBoxThree p {
  color: #000;

  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.singleFlightBoxThree>div {
  width: 105px;
}

.singleFlightBoxThree>div img {
  width: 100%;
}

.singleFlightBoxThree p {
  font-weight: 500;

}

.singleFlightBoxThree span {
  color: #d90429;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}



.singleFlightBoxFour span {
  color: #000;
  font-family: 'Montserrat';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.singleFlightBoxFour p {
  color: #000;
  font-family: 'Montserrat';
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.singleFlightBoxFour h5 {
  color: #000;
  font-family: 'Montserrat';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}


.singleFlightBoxFive span {
  color: #21325d;
  font-family: 'Montserrat';
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.singleFlightBoxFive p {
  color: #21325d;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border-radius: 2px;
  background: #D2F8E7;
  padding: 3px 5px;
}

.singleFlightBoxSix {
  color: #21325d !important;
}


.singleFlightBoxSeven p {
  font-size: 20px;
  font-weight: 700;
  color: #21325d;
  font-family: 'Montserrat';
}

.singleFlightBoxSeven button {
  padding: 8px 10px;
  border-radius: 23px;
  /* background: linear-gradient(180deg, #d90429, #d904279c); */
  color: #FFF;
  font-family: 'Montserrat';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  transition: 0.5s;
  background-size: 200% auto;
  background-image: linear-gradient(to right, #d90429 0%, #EA384D 51%, #d90429 100%);
}

.singleFlightBoxSeven button:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.daySize {
  font-size: 14px;
}


hr {
  opacity: 0.7 !important;
}

.stopBef {
  position: relative;
}

.stopBef hr::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: red;
  opacity: 0.7 !important;
}


.pagination .active>.page-link {
  background-color: #d90429 !important;
  border-color: white;
  color: white !important;
}

.pagination .page-link {
  color: #d90429 !important;
}

.loadingflightgradient {
  background-Image: linear-gradient(to right bottom, #a0a2a7, #989d9f, #adafaf, #c6c9c8, #bbbdb7);
  animation: gradient 15s ease infinite;

}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }

  50% {
      background-position: 100% 50%;
  }

  100% {
      background-position: 0% 50%;
  }
}


.emiBOx {
  display: flex;
  flex-direction: column;
  gap: 4px;

  cursor: pointer;
}

.emiBOx p {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: blue !important;
}

.emiBOx span {
  font-weight: 600 !important;
  color: blue !important;
  font-size: 12px !important;
}


@keyframes wave-lines {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.custom-height-sm {
  height: 420px;
  padding: 15px; /* Full height for small devices */
}

@media (min-width: 992px) {
  /* Large devices (lg and up) */
  .custom-height-lg {
      height: auto !important ; /* Fixed height for large devices */
  }
}
@media (min-width: 768px) {
  /* Large devices (lg and up) */
  .flight-name-new {
      width: 150px ; /* Fixed height for large devices */
  }
}
@media (max-width: 600px) {
  /* Large devices (lg and up) */
  .mainimgFlightSearch {
      /* height: 420px !important; Fixed height for large devices */
  }
  .bookBottomThree{
    gap:10px
  }
  .bookbottomBox {
    gap:10px
  }
  .bookBottomFour {
    padding: 0 !important;
    width: 100%;
  }
}





