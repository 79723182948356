.stTrPackBox {

    width: 100%;
    height: 300px;
}

.stTrPackBox img {
    width: 100%;
    height: 100%;
}


.sttrPara p {

    font-weight: 600;
    font-size: 14px;
}


.sttrPara P:nth-child(1) {
    font-weight: 800;
    margin-top: 8px;
}

.formTitleTRPack {
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
}


.trbann {
    display: flex;
    justify-content: center;
}

.trbann img {
    width: 100%;
}


.sttrcard {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #ddd;
    cursor: pointer;
}

.sttrcard img {
    border-radius: 5px;
}


.thankYOuu {
    color: rgb(0, 131, 0);
    font-weight: 600;
}


@media(max-width:600px) {

    .blogHeaad h3 {
        font-size: 16px !important;
        text-align: center;
    }
}