.hotelDetailsDesc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #FFF;
    padding: 15px;
    border-radius: 8px 8px 0px 0px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 2;
    position: relative;
}


.hotelDetailsDesc>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.hotelDetailsDesc>div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}


.bookingDetailsGuestDesc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 6px;
    background: #fff;
    margin: 15px 0px;
}


.bookingDetailsGuestHeaderDesc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #F9EDED;
    border: 1px solid lightgray;
    padding: 15px;
    border-radius: 6px;
}

.bookingDetailsGuestHeaderDesc p {
    font-weight: 700;
}


.passengerDetailsGuestBody {
    border: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    /* gap: 10px; */
    margin: 10px;
    column-gap: 20px;
    padding: 12px 15px;
}


.guestDetailsHistoryDesc {
    border-radius: 8px;
    padding: 8px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
}

.guestDetailsHistoryDesc button {
    padding: 14px 42px;
    border-radius: 10px;
    background: #d90429;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.30);
    color: #FFFBFB;
    border: 2px solid #d90429;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    transition: 0.2s all;
}


.guestDetailsHistoryDesc button:hover {
    background: white;
    color: #d90429;

}