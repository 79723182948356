.FlightRightSkeleton{
    display: flex;
    // background-color: red;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    .FlightRightSkeletonItem{
        // border: 1px solid #d1d5db;
        border: none;
    // padding: 12px 16px;
    height: 100px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    background-color: #f2f2f2;
    position: relative;
    overflow: hidden;
   &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg, rgba(241, 241, 241, 0) 0, rgba(241, 241, 241, 0.2) 20%, rgba(241, 241, 241, 0.5) 60%, rgba(241, 241, 241, 0));
        animation: shimmer 2s infinite;
        content: "";
    }
    }
}