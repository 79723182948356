/* src/Login.css */
.login-container-inventory {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  padding: 20px;
  background-image: url("https://skytrails.s3.amazonaws.com/random/hotelInventory.jpg");
  background-position: center;
  opacity: 0.8;
}

.login-form-wrapper {
  background: transparent;
  padding: 40px;
  border-radius: 10px;

  /* Box-shadow: balanced shadow on all sides */

  /* Add a nice border color */

  text-align: center;

  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18); */

  background: rgba(255, 255, 255, 0.45);
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.login-heading-inventory {
 font-weight: 600;
}

.ant-form-item {
  margin-bottom: 16px;
}

.login-button-inventory-inventory {
  width: 100%;
  background-color: #1890ff;
  border: none;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); /* 3D shadow effect */
}

.login-button-inventory-inventory:hover {
  background-color: #40a9ff; /* Lighter shade on hover */
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2); /* Deepen shadow on hover */
  transform: translateY(-3px); /* Raise the button on hover */
}

.login-button-inventory-inventory:active {
  background-color: #096dd9; /* Darker color on click */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Reduce shadow on click */
  transform: translateY(2px); /* Lower the button when clicked */
}

.login-button-inventory {
  width: 100%;
  background-color: #1890ff;
  border: none;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);

  /* Adding animation for the button on load */
  animation: popIn 0.6s ease-in-out;
}

@keyframes popIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.login-button-inventory:hover {
  background-color: #40a9ff;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px) scale(1.05); /* Adds a slight scaling on hover */
}

.login-button-inventory:active {
  background-color: #096dd9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(2px) scale(0.98); /* Slight shrinking when pressed */
}

/* Adding a pulsing animation when the button is in a loading state */
.loading-button {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }
}

/* .custom-form .ant-form-item-label > label {
  color: white;
} */

.register-link p{
  font-weight: 500;
}

.register-link p + p {
  font-weight: 500;
}
