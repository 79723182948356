@import "../mixins.scss";

.newOneWayMainOneway2 {
  display: flex;
  align-items: center;
  position: relative;

  .newOnewaySingle {
    background-color: white;
    min-height: 55px;
    padding: 2px 10px;
    border-radius: 6px;
    border: 1px solid#D1D5DB !important;

    @include md {
      min-height: 65px;
      padding: 6px 10px;
    }

    .ant-select-single {
      height: 26px !important;

      @include md {
        height: 28px !important;
      }
    }

    .ant-select {
      width: 100%;

      .ant-select-selector {
        border: none !important;
        padding: 0 !important;
        line-height: 1.3 !important;

        .ant-select-selection-item {
          font-size: 16px;
          font-weight: 600;
          font-family: "Montserrat";

          @include md {
            font-size: 16px;
          }
        }
      }
    }
    .ant-picker {
      width: 100%;
      padding: 0 !important;
      border: none;

      .ant-picker-input {
        input {
          padding-top: 5px;
          font-size: 16px;
          font-weight: 600;
          font-family: "Montserrat";
          line-height: 0 !important;

          @include md {
            font-size: 16px;
          }
        }
      }
    }

    .smMargin {
      margin-left: 123px !important;
      @include md {
        margin-left: 3rem !important;
      }
    }

    > span {
      font-size: 13px;
      color: #6b7280;
    }

    .travelContent {
      display: flex;
      flex-direction: row;
      gap: 10px;
      font-size: 16px;
      font-weight: 600;
      font-family: "Montserrat";

      p {
        padding-top: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.3;

        span {
          font-size: 12px !important;
        }
      }
      @include md {
        font-size: 16px;
      }
    }

    .smallCustomCalender {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 105px !important;

      .modalContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
      }

      .modalContent {
        width: 90%;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        max-height: 80%;
      }

      .weekdaysContainer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      .weekday {
        width: 14%;
        text-align: center;
        font-weight: bold;
        color: #555;
      }

      .monthContainer {
        margin-bottom: 20px;
      }

      .monthLabel {
        font-size: 18px;
        text-align: center;
        margin: 10px 0;
      }

      .daysContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 1px;
      }

      .day {
        width: 13%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.5px solid #f1f1f1;
        border-radius: 3px;
        margin-bottom: 5px;
        margin-right: 3px;
      }

      .dayText {
        font-size: 13px;
        font-weight: 500;
      }

      .selectedDay {
        background-color: #ff9ca7;
      }

      .inRangeDay {
        background-color: #ff9ca7;
      }

      .selectedDayText {
        color: #fff;
        font-weight: bold;
      }

      .disabledDay {
        background-color: #e0e0e0;
      }

      .disabledDayText {
        color: #aaa;
      }

      .scrollableContainer {
        max-height: 60vh;
        overflow-y: auto;
      }

      .selectedDates {
        margin-top: 0px;
        font-size: 16px;
      }
    }
  }

  .multicityButton {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #e73c34;
    color: white;
    border: none;
    outline: none;
    font-size: 17px;
    font-weight: 600;
    min-height: 68px;
  }

  .roundlogoFlight {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    width: 40px;
    top: 23%;
    left: 79%;
    transform: translate(-34%, -50%);

    svg {
      transform: rotate(90deg);

      @include md {
        transform: rotate(0deg);
      }
    }

    @include md {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 2;
      width: 40px;
      top: 50%;
      left: 24%;
      transform: translate(-34%, -50%);
    }
  }

  .hotelFormInnerBtton button {
    width: 100% !important;
    min-height: 68px !important;
    background-color: var(--primary);

    &:hover {
      background-color: var(--primaryHover) !important;
      color: var(--pureWhite);
    }
  }

  .hotelColor button {
    background-color: var(--primaryHotel) !important;
    color: var(--pureWhite);

    &:hover {
      background-color: var(--primaryHoverHotel) !important;
      color: var(--pureWhite);
    }
  }
}

.newBusFormInner {
  display: flex;
  align-items: center;
  position: relative;

  .newBusSingle {
    background-color: white;
    min-height: 55px;
    padding: 2px 10px;
    border-radius: 6px;
    border: 1px solid#D1D5DB !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    @include md {
      min-height: 65px;
      padding: 6px 10px;
    }

    .newbusSingleInside {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      > span {
        font-size: 13px;
        color: #6b7280;
      }
    }

    .ant-select-single {
      height: 26px !important;

      @include md {
        height: 28px !important;
      }
    }

    .ant-select {
      width: 100%;

      .ant-select-selector {
        border: none !important;
        padding: 0 !important;
        line-height: 1.3 !important;

        .ant-select-selection-item {
          font-size: 16px;
          font-weight: 600;
          font-family: "Montserrat";

          @include md {
            font-size: 16px;
          }
        }
      }
    }
    .ant-picker {
      width: 100%;
      padding: 0 !important;
      border: none;

      .ant-picker-input {
        input {
          padding-top: 5px;
          font-size: 16px;
          font-weight: 600;
          font-family: "Montserrat";
          line-height: 0 !important;

          @include md {
            font-size: 16px;
          }
        }
      }
    }

    .smMargin {
      margin-left: 123px !important;
      @include md {
        margin-left: 3rem !important;
      }
    }

    > span {
      font-size: 13px;
      color: #6b7280;
    }

    .travelContent {
      display: flex;
      flex-direction: row;
      gap: 10px;
      font-size: 16px;
      font-weight: 600;
      font-family: "Montserrat";

      p {
        padding-top: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.3;

        span {
          font-size: 12px !important;
        }
      }
      @include md {
        font-size: 16px;
      }
    }

    .smallCustomCalender {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 105px !important;

      .modalContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
      }

      .modalContent {
        width: 90%;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        max-height: 80%;
      }

      .weekdaysContainer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      .weekday {
        width: 14%;
        text-align: center;
        font-weight: bold;
        color: #555;
      }

      .monthContainer {
        margin-bottom: 20px;
      }

      .monthLabel {
        font-size: 18px;
        text-align: center;
        margin: 10px 0;
      }

      .daysContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 1px;
      }

      .day {
        width: 13%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.5px solid #f1f1f1;
        border-radius: 3px;
        margin-bottom: 5px;
        margin-right: 3px;
      }

      .dayText {
        font-size: 13px;
        font-weight: 500;
      }

      .selectedDay {
        background-color: #ff9ca7;
      }

      .inRangeDay {
        background-color: #ff9ca7;
      }

      .selectedDayText {
        color: #fff;
        font-weight: bold;
      }

      .disabledDay {
        background-color: #e0e0e0;
      }

      .disabledDayText {
        color: #aaa;
      }

      .scrollableContainer {
        max-height: 60vh;
        overflow-y: auto;
      }

      .selectedDates {
        margin-top: 0px;
        font-size: 16px;
      }
    }
  }

  .multicityButton {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #e73c34;
    color: white;
    border: none;
    outline: none;
    font-size: 17px;
    font-weight: 600;
    min-height: 68px;
  }

  .roundlogoFlight {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    width: 40px;
    top: 23%;
    left: 79%;
    transform: translate(-34%, -50%);

    svg {
      transform: rotate(90deg);

      @include md {
        transform: rotate(0deg);
      }
    }

    @include md {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 2;
      width: 40px;
      top: 50%;
      left: 24%;
      transform: translate(-34%, -50%);
    }
  }

  .roundlogoBus {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    width: 40px;
    top: 28%;
    left: 79%;
    transform: translate(-34%, -50%);

    svg {
      transform: rotate(90deg);

      @include md {
        transform: rotate(0deg);
      }
    }

    @include md {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 2;
      width: 40px;
      top: 50%;
      left: 32%;
      transform: translate(-26%, -50%);
    }
  }

  .hotelFormInnerBtton button {
    width: 100% !important;
    min-height: 68px !important;
    background-color: var(--primary);

    &:hover {
      background-color: var(--primaryHover) !important;
      color: var(--pureWhite);
    }
  }
}

.flightSearchButtonBox {
  position: relative;
  top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -45px;

  button {
    background-color: #e73c34 !important;
    color: white !important;
    font-size: 18px !important;
    border: none !important;
    padding: 8px 36px !important;
    height: unset !important;

    width: 100%;
    @include md {
      width: unset !important;
    }

    &:hover {
      color: white !important;
      background-color: var(--primaryHover) !important;
    }
  }
}

.Oneway2Search {
  width: 100% !important;
  height: 100% !important;
  background-color: #4f46e5 !important;
  text-align: center !important;
  min-height: 64px !important;
  color: #fff !important;
  border: none !important;
}
