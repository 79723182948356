/* ForgetPassword.css */
.forget-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f2f5;
    padding: 20px;
  }
  
  .forget-password-form-wrapper {
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .forget-password-heading {
    text-align: center;
    margin-bottom: 24px;
  }
  
  .forget-password-button {
    width: 100%;
  }
  
  @media (max-width: 576px) {
    .forget-password-container {
      padding: 10px;
    }
  
    .forget-password-form-wrapper {
      padding: 16px;
    }
  
    .forget-password-heading {
      font-size: 24px;
    }
  }
  

  /* ForgetPassword.css */
.forget-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f2f5;
    padding: 20px;
  }
  
  .forget-password-form-wrapper {
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .forget-password-heading {
    text-align: center;
    margin-bottom: 24px;
  }
  
  .forget-password-button {
    width: 100%;
  }
  
  @media (max-width: 576px) {
    .forget-password-container {
      padding: 10px;
    }
  
    .forget-password-form-wrapper {
      padding: 16px;
    }
  
    .forget-password-heading {
      font-size: 24px;
    }
  }
  