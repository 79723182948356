@import url("https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:wght@300;400;500;600;700;800&display=swap");

.margin-pecentage {
  margin: 0% 7%;
  font-family: "Montserrat", sans-serif;
}

.oneWayAbsDesign {
  width: 100%;
  z-index: 2;
}

.oneWayBg {
  border-radius: 0px 10px 10px 10px;
  position: relative;
}

.your-container {
  display: grid;
  border-radius: 10px;
  width: 100% !important;
  gap: 12px;
  grid-template-columns: 21% 22% 18% 18% 20%;
}

.your-containerReturn {
  display: grid;
  gap: 12px;
  border-radius: 10px;
  grid-template-columns: 18% 18% 20% 20% 20%;
}

@media screen and (max-width: 1200px) {
  .your-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .your-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .your-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 1200px) {
  .your-containerReturn {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .your-containerReturn {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .your-containerReturn {
    grid-template-columns: repeat(2, 1fr);
  }
}
.from-container {
  padding: 6px 21px;
  border-top-left-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d1d5db);
  background: var(--white, #fff);
  gap: 1px;
  display: inline-flex;
  position: relative;
}

.from-container:nth-child(2) {
  padding: 6px 34px;
}

.from-container span {
  color: #071c2c;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.from-container input {
  color: #3f454a;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* width: 141px; */
  width: 100%;
  height: 35px;
}

.from-container input::placeholder {
  color: #071c2c;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* width: 141px; */
  width: 100%;
  height: 35px;
}

.from-container label {
  color: #3f454a;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* width: 141px; */
  width: 100%;
  border: none;
  outline: none;
}

.react-datepicker-wrapper input {
  border: none !important;
  background-color: transparent;
  width: 80%;
}

.react-datepicker-wrapper input:active {
  border: none !important;
}

.react-datepicker-wrapper input:focus-visible {
  border: none !important;
  outline: none !important;
}

.from_search_div {
  display: flex;
  gap: 5px !important;
  align-items: center;
  outline: none;
  border: none;
  padding-left: 15px;
  border-radius: 4px !important;
  /* background-color: #071C2C; */
}

.from_search_div input::placeholder {
  font-weight: 600;
}

.date-container {
  /* width: 122px; */
  /* height: 57px; */
  padding: 4px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.from-container span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* backgroundColor: "white",
borderRadius: "10px",
zIndex: 1999900,
width: "100%",
boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
textAlign: "left",
cursor: "pointer", */

.from-search-results-one {
  position: absolute;
  left: -5px;
  /* bottom: 30px; */
  /* top: 16px; */
  bottom: 61px;
  cursor: pointer;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  z-index: 9999;
  border: none;
  outline: none;
  padding-left: 5px;

  /* height: 400px; */
  /* overflow: hidden; */
}

.from_Search_Container {
  text-align: center;
  color: black;
  padding-left: 0;
  width: 100%;
  /* top: 33px; */
  background: white;
  /* border: 1px solid gray; */
  /* border-radius: ; */
  z-index: 100;
  position: absolute;
  /* box-shadow: 1px 1px 5px gray; */
}

.from-search-results-one ul li {
  list-style-type: none;
}

.onewayResultBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.onewayResultFirst {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  gap: 10px;
}

.resultOriginName p {
  font-weight: 600;
}

.resultAirportCode p {
  font-weight: 600;
}

.travellerButton {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: transparent;
  gap: 3px;
  border: none;
  outline: none;
}

.travellerButton span {
  color: #071c2c;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.onewaySearch {
  padding: 25px 21px;
  border-top-left-radius: 12px;
  border-right: 1px #888 solid;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: relative;
  border-right: unset !important;
  padding: 0 0 !important;
}

.onewaySearch-btn {
  padding: 25px 21px;
  border-top-left-radius: 12px;
  border-right: 1px #888 solid;
  flex-direction: column;
  /* justify-content: space-between;
  align-items: flex-start; */
  display: flex;
  justify-content: center;

  align-items: center;
  gap: 7px;
  /* display: inline-flex; */
  position: relative;
  border-right: unset !important;
  padding: 0 0 !important;
}

.searchButt {
  border-radius: 16px;
}
.onewaySearch-btn .searchButt {
  /* width: 100%; */
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  background-color: #fff;
  /* width: 100%; */
  /* height: 100%; */
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
  border: none;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  border-radius: 16px;
  background: #e73c34;
  padding: 29px;
  background-size: 200% auto;
  color: white;
  /* box-shadow: 0 0 20px #eee; */
  /* display: block; */
}

.onewaySearch-btn .searchButt:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.onewaySearch-btn .searchButt h3 {
  color: white;
  font-size: 21px;
}

.travellerContainer {
  padding-top: 10px;
  padding-bottom: 11px;
  padding-left: 13px;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d1d5db);
  background: var(--white, #fff);
  border-top-left-radius: 12px;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  /* align-items: flex-start; */
  /* display: inline-flex; */
}

.travellerButton {
  cursor: pointer;
}

.travellerButton p span {
  color: #071c2c;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.travellerButton p {
  color: #071c2c;
  font-family: Montserrat;
  /* font-size: 24px; */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.wrapper_oneway {
  background: #fff;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  display: flex;
  width: 100%;
  font-family: "Montserrat";
  margin-top: 8px;
}

.css-13xfq8m-MuiTabPanel-root {
  /* padding: 7px 19px !important; */
  position: relative !important;
  z-index: 3 !important;
}

.wrapper_oneway .option {
  height: 35px;
  /* width: 120px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 5px;
  border-radius: 10px;
  cursor: pointer;
  background: #f3f3f3;
  transition: all 0.3s ease;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  font-family: "Montserrat";
}

/* .wrapper_oneway .wraOne {
    width: 50%;
} */

.wrapper_oneway p {
  width: 100px;
}

.wrapper_oneway text {
  color: #071c2c;
  font-family: Montserrat;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#option-1:checked:checked ~ .option-1 {
  border-color: #ff8900;
  background: #21325d;
}

.fare_search_oneWay button {
  border: 2px solid #d90429;
  border-radius: 8px;
  padding: 8px 20px;
  background-color: #d90429;
  color: white;
  font-weight: 600;
  transition: 0.3s all;
}

.fare_search_oneWay button:hover {
  background-color: #fff;
  color: #d90429;
}

/* traveller modal box design  */

.travellerModal {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
}

.travellerModal > div h3 {
  font-weight: 600;
  font-size: 20px;
  color: #21325d;
  font-family: "Montserrat";
}

.travellerPeople {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  gap: 15px;
  padding: 20px 0px;
}

.travellerPeople > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 30px;
  align-items: center;
}

.TravelerCounter p {
  color: #21325d;
  font-weight: 600;
  font-size: 16px;
}

.TravelerCounter button {
  padding: 10px 15px;
  background-color: #d90429;
  color: white;
  border: none;
  outline: none;
  font-weight: 700;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TravelerCounter > div {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100px;
  justify-content: space-between;
  align-items: center;
}

.TravelerCounter > div span {
  font-weight: 700;
}

.classButtonTravel {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0;
  margin-top: 15px;
}

.classButtonTravel li {
  list-style-type: none;
  padding: 6px 12px;
  font-size: 12px;
  margin: 0;
  border: 2px solid #d90429;
  color: #d90429;
  margin: 0px 5px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 600;
  border-radius: 5px;
}

.classButtonTravel li:hover {
  background-color: #d90429;
  color: white;
}

.returnConstruction {
  height: 300px;
  padding: 0px 0px 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  .from-container span {
    font-size: 13px;
  }

  .travellerButton span {
    font-size: 13px;
  }

  .from-container label {
    font-size: 19px;
  }

  .from-container input {
    font-size: 19px;
  }

  .travellerButton p {
    font-size: 19px !important;
  }
}

.oneWayBg .css-d16633-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff;
  background-color: #21325d;
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .roundlogo {
    position: static;
  }
}

input.custominputplaceholder::placeholder {
  border: none;
  font-size: 20px;
  outline: none;
  color: #b8babb;
  font-weight: 700;
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
}

/* Apply the shake animation */
.shake {
  animation: shake 0.5s ease-in-out;
}
