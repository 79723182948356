@import "../../../mixins.scss";
.card-flight {
  border: 1px solid #d1d5db;
  padding: 12px 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  .card-box-1 {
    display: flex;
    gap: 8px;
    flex-direction: column;
    @include md {
      gap: 16px;
    }
    .meal {
      color: #2dc4cf;
    }
    .detail {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 10px;

      @include md {
        flex-direction: row;
      }
      .flight-box-img {
        display: flex;
        gap: 5px;
        width: auto;
        @include md {
          flex-direction: column;
          display: flex !important;
          gap: 28px !important;
          align-items: center;
          flex-direction: row;
          justify-content: flex-start;
          gap: 10px !important ;
          width: 190px;
        }
        .air-img {
          width: 30px;
          height: 30px;
          @include md {
            width: 40px;
            height: 40px;
          }
          img {
            width: 100%;
            height: 30px;
            object-fit: contain;
            @include md {
              width: 40px;
              height: 40px;
            }
          }
        }
        .code-name {
          display: flex;
          gap: 8px;
          align-items: center;
          @include md {
            flex-direction: column;
            justify-content: center;
          }
          p {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
      .detail-box-right {
        display: flex;
        flex: 1;
        justify-content: space-between;
        gap: 5px;

        .flight-box-2 {
          display: flex !important;
          justify-content: space-between;
          flex: 1;
          gap: 10px;
          justify-content: space-between;
          @include md {
            justify-content: center;
          }
          // gap: 28px !important;
          .flight-time-1 {
            display: flex;
            flex-direction: column;
            gap: 4px;
            p {
              font-size: 12px;
              font-weight: 600;
              line-height: 19.5px;
              @include md {
                font-weight: 500;
                font-size: 16px;
              }
            }
            span {
              display: none;
              @include md {
                font-size: 10px;
                display: block;
              }
            }
          }
          .flight-time-2 {
            display: flex;
            flex-direction: column;
            gap: 4px;
            height: 100%;

            justify-content: space-between;
            align-content: center;
            p {
              font-size: 10px;
              font-weight: 500;
              line-height: 9.75px;
              text-align: center;
            }
            span {
              color: #e73c33;
              font-size: 10px;
              font-weight: 500;
              line-height: 9.75px;
              text-align: center;
            }
          }
          .flight-time-3 {
            display: flex;
            flex-direction: column;
            gap: 4px;
            height: 100%;

            justify-content: space-between;
            align-content: center;
            p {
              font-size: 8px;
              font-weight: 500;
              line-height: 9.75px;
            }
            button {
              border: none;
              outline: none;

              span {
                color: #e73c33;
                font-size: 14px;
                font-weight: 600;
                line-height: 17.5px;
              }
              p {
                color: #e73c33;
                font-size: 14px;
              }
            }
          }
        }
        .flight-box-3 {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          height: 100%;
          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 19.5px;
          }
          button {
            border: none;
            outline: none;
            background-color: transparent !important;

            span {
              color: #e73c33;
              font-weight: 600;
              font-size: 10px;
              line-height: 17.5px;
              @include md {
                font-size: 14px;
              }
            }
            p {
              color: #e73c33;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .card-box-2 {
    display: flex;
    justify-content: end;
    align-items: center;
    button {
      background-color: #ebf5ff;
      padding: 4px 8px;
      gap: 4px;
      border-radius: 4px;
      display: flex;
      border: none;
      outline: none;
      p {
        font-size: 16px;
        line-height: 24px;
        color: #1a56db;
      }
      span {
        font-size: 16px;
        line-height: 24px;
        color: #1a56db;
        font-weight: 600;
      }
    }
  }
  .card-box-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap: 15px;
    .detail-options {
      display: flex;
      flex: 1;
      justify-content: space-between;
      width: 100%;
      gap: 10px;
      button {
        flex: 1;
        outline: none;
        color: #e73c33;
        border: none;
        outline: none;
        border-bottom: 2px solid #e73c33;
        padding: 12px, 8px, 12px, 8px;
        font-size: 16px;
        line-height: 22.4px;
        background-color: transparent;
      }
    }
    .flight-information {
      display: flex;
      width: 100%;
      flex-direction: column;

      .labale-flight {
        display: flex;
        // background-color: #1A56DB;
        .labale-left {
          flex: 3;

          border: 1px solid #f2f2f2;
          border-right: 0px !important;
          padding: 10px;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          color: #111928;
        }
        .labale-right {
          flex: 1;

          border: 1px solid #f2f2f2;
          padding: 10px;
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          color: #111928;
          text-align: center;
        }
      }
    }
  }
}
