@import "../../mixins.scss";

.headers {
  position: fixed;
  transform: translateY(0);
  width: 100%;
  // height: 85px;
  top: 0;
  z-index: 99999;
  transition: all ease 0.5s;
  // z-index: 100;
  padding-right: 10px;

  @include md {
    padding: unset;
  }

  &.tops {
    // background: rgba(255, 255, 255, 0.55);
    // backdrop-filter: blur(3.5px);
    // -webkit-backdrop-filter: blur(3.5px);
    transform: translateY(-160px);
    // display: none;
  }

  &.shows {
    // background-color: var(--black5);
    background-color: var(--pureWhite);
  }

  &.hides {
    transform: translateY(-60px);
  }

  .contentWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mainlogo {
    cursor: pointer;

    img {
      height: 36px;

      @include md {
        height: 50px;
      }
    }
  }

  .menuItemLogin {
    height: 60px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0 10px;
    color: var(--primaryBlue);
    font-weight: 500;
    position: relative;
    display: none;

    @include md {
      display: flex;
    }

    &:hover {
      color: var(--primary) !important;
    }

    h4 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
      padding: 10px 15px;
      background-color: #f1f1f13d;
      border-radius: 25px;

      border: 2px solid var(--primary);
      // padding: 5px 12px;

      // background-color: #f1f1f1;
    }

    button {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
      padding: 10px 15px;
      background-color: #f1f1f13d !important;
      border-radius: 25px;
      color: var(--primary) !important;
      border: 2px solid var(--primary);

      &:hover {
        color: var(--primaryHover) !important;
        border: 2px solid var(--primary) !important;
      }
    }

    svg {
      font-size: 18px;
    }

    cursor: pointer;

    color: var(--primary);
    &:hover {
      color: var(--primaryHover);
    }
    &.active {
      color: var(--primary);
    }
  }

  .menuItems {
    list-style-type: none;
    display: none;
    align-items: center;
    padding-left: 0 !important;
    margin-bottom: 0 !important;

    @include md {
      display: flex;
    }

    .menuItem {
      height: 60px;
      display: flex;
      align-items: center;
      gap: 5px;
      margin: 0 10px;
      color: var(--primaryBlue);
      font-weight: 500;
      position: relative;

      a {
        text-decoration: none !important;

        color: var(--primaryBlue);
        &:hover {
          color: var(--primary);
        }
      }

      &.searchIcon {
        margin-right: 0;
      }

      svg {
        font-size: 18px;
        color: var(--primary) !important;
      }

      cursor: pointer;

      &:hover {
        color: var(--primary);
      }
      &.active {
        color: var(--primary);
      }
    }
  }

  .mobileMenuItems {
    display: flex;
    align-items: center;
    gap: 20px;

    @include md {
      display: none;
    }

    svg {
      font-size: 18px;
      color: var(--primaryBlue);
    }
  }

  &.mobileView {
    background: var(--pureWhite);

    .menuItems {
      display: flex;
      position: absolute;
      top: 60px;
      left: 0;
      background: var(--pureWhite);
      flex-direction: column;
      width: 100%;
      padding: 20px 0;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      animation: mobileMenu 0.3s ease forwards;
      height: 100vh;
      overflow: hidden;
      overflow-y: scroll;

      .menuItem {
        font-size: 20px;
        width: 100%;
        height: auto;
        padding: 15px 20px;
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        // &:last-child {
        //   display: none;
        // }
      }
    }
  }

  .searchBar {
    width: 100%;
    height: 60px;
    background-color: white;
    position: absolute;
    top: 60px;
    animation: mobileMenu 0.3s ease forwards;

    .searchInput {
      display: flex;
      align-items: center;
      height: 40px;
      margin-top: 10px;
      width: 100%;

      svg {
        font-size: 20px;
        flex-shrink: 0;
        margin-left: 10px;
        cursor: pointer;
      }

      input {
        width: 100%;
        height: 50px;
        background-color: white;
        outline: 0;
        border: 0;
        border-radius: 30px 0 0 30px;
        padding: 0 15px;
        font-size: 14px;

        @include md {
          height: 60px;
          font-size: 20px;
          padding: 0 30px;
        }
      }
    }
  }
}

@keyframes mobileMenu {
  0% {
    transform: translateY(-130%);
  }

  100% {
    transform: translateY(0);
  }
}

.ant-dropdown {
  a {
    list-style-type: none !important;
    text-decoration: none !important;
  }
}
