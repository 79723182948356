.onewaynew {
  /* background: #fff; */
  padding: 11px 0;
}

.onewaynewinner {
  width: 100%;
  margin: 0 auto 20px;
  border-radius: 10px;
  display: -webkit-box;
  /* display: -ms-flexbox; */
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.onewayDatePicker {
  /* width: max-content; */
  font-weight: 700;
  font-size: larger;
}


.onewaycontainernew {
  background: rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.fromcity {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.fromcityinputfield {
  line-height: 36px;
  border: 0;
  outline: 0;
  background: none;
  min-width: 140px;
  max-height: 36px;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  font-weight: 900;
}

/* .App {
    font-family: sans-serif;
  }
   */
.dropdown {
  position: relative;
  color: #333;
  cursor: default;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  transition: all 200ms ease;
  width: 100%;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.hoverCity {
  background-color: #e1dede;
  width: 100%;
}

/* .react-datepicker-popper {
  z-index: 999 !important;
}

.react-datepicker {
  display: flex !important;
  position: absolute !important;
  z-index: 999 !important;
  margin-left: -50px !important;
} */

@media screen and (max-width: 768px) {
  .newonewaydesign {
    gap: 12px;
  }
}

input.custominputplaceholder::placeholder {
  border: none;
  font-size: 20px;
  outline: none;
  font-weight: 700;
}