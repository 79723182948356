.careerMainCard {
  padding: 20px;
  background-color: white;
  width: 100%;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  border: 1px solid lightgray;
}

.careerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.careerHeaderInn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  /* background-color: orange; */
}

.careerComName {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.careerComName p {
  font-weight: 600;
}

.careerComName h6 {
  margin-bottom: 0 !important;
  text-align: left !important;
}

.careerExp p {
  font-weight: 600;
  font-size: 14px;
  padding: 4px 10px;
  background-color: #188d18;
  border-radius: 4px;
  color: white;
}

.JobTitleCareer {
  font-size: 18px;
}

.JobTitleCareer h2 {
  margin-bottom: 0 !important;
  font-size: 20px;
}

.skillCareer span {
  padding: 2px 7px;
  background-color: #f2f2f2;
  margin-left: 7px;
  font-weight: 600;
  font-size: 13px;
}

.skillCareer p {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
}

.jobTypeCareer {
  display: flex;
  gap: 10px;
}

.jobTypeCareer p {
  margin-bottom: 0 !important;
  padding: 4px 10px;
  background-color: white;
  border: 1px solid gray;
  color: gray;
  font-weight: 600;
  border-radius: 4px;
  line-height: 1;
}

.jobLocCareer {
  display: flex;
  gap: 5px;
}

.jobLocCareer p {
  font-size: 14px;
}

.DescCareer .sm {
  color: #e73c34;
  cursor: pointer;
  font-size: 13px !important;
}

.DescCareer p {
  font-size: 14px;
}


.industryCareer {
  display: flex;
  gap: 5px;
}

.careerApplyButton button {
  color: white !important;
  background-color: var(--primary) !important;
}

.careerApplyButton button:hover {
  background-color: var(--primaryHover);
  color: white !important;
}