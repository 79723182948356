.newReturnForm input {
    font-size: 22px !important;
    font-weight: 600 !important;
    font-family: 'Montserrat';
    line-height: 0px !important;
}

.newReturnForm svg {
    fill: #111 !important;
}

.newReturnForm .ant-picker {
    padding: 0px 11px 0px !important;
    border-width: 0px !important;
}

.newReturnForm .ant-select-selection-item {
    font-size: 22px !important;
    font-weight: 600 !important;
    font-family: 'Montserrat';
    /* padding: 6px 0px !important; */
}

.newReturnForm .ant-select-selector {
    padding: 0px 7px !important;
    border: none !important;
}

.newReturnForm .ant-select-selector:focus-visible {
    padding: 0px 7px !important;
    border: none !important;
    box-shadow: unset !important;
}

.newReturnForm .ant-select-single {
    height: unset !important;
}

.newReturnForm .ant-select:focus-visible {
    box-shadow: unset !important;
}

.ant-select-focused:where(.css-j9bb5n).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    box-shadow: unset !important;

}

.ant-select-focused:where(.css-dev-only-do-not-override-j9bb5n).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    box-shadow: unset !important;

}


.newReturnSingleBox {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 3px 0px;
    border-radius: 5px;

}
.newReturnSingleBoxReturn{
    border: 1px solid #D1D5DB;
}

.newReturnSingleBox:nth-of-type(2) {
    padding: 3px 20px !important;
}

.newReturnSingleBox:where(.css-j9bb5n).ant-picker-range .ant-picker-active-bar {
    bottom: -1px;
    height: 2px;
    background: #E73C34 !important;
    opacity: 0;
    transition: all 0.3s ease-out;
    pointer-events: none;
}

.newReturnSingleBox .nrsb {
    padding-left: 10px;
    font-weight: 600;
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
}

.newReturnSingleBox .nrsbpara {
    font-size: 22px !important;
    font-weight: 600 !important;
    font-family: 'Montserrat';
    padding: 0px 11px 0px !important;
    line-height: 30px !important;
}


.returnButton {
    background-color: #E73C34 !important;
    color: white;
    font-size: 20px;
}


:where(.cssj9bb5n).ant-btn {
    height: unset !important;
}

.returnButton button {
    height: unset !important;
    padding: 8px 36px !important;
    border-color: #E73C34 !important;
}



:where(.css-j9bb5n).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff !important;
    border-color: #E73C34 !important;
    background: #E73C34 !important;
}

:where(.css-dev-only-do-not-override-j9bb5n).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff !important;
    border-color: #E73C34 !important;
    background: #E73C34 !important;
}


:where(.css-j9bb5n).ant-picker-range .ant-picker-active-bar {

    background: #E73C34 !important;

}

:where(.css-j9bb5n).ant-picker-range .ant-picker-active-bar {
    background: #E73C34 !important;
}

:where(.css-j9bb5n).ant-select-dropdown .ant-select-item {

    border-bottom: 1px solid #ddd !important;
}


.ant-select-focused:where(.css-j9bb5n).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: unset !important;
    box-shadow: unset !important;
    outline: 0;
}






@media only screen and (max-width: 668px) {

    .newReturnSingleBox:where(.css-dev-only-do-not-override-j9bb5n).ant-picker-dropdown .ant-picker-decade-panel,
    :where(.css-dev-only-do-not-override-j9bb5n).ant-picker-dropdown .ant-picker-year-panel,
    :where(.css-dev-only-do-not-override-j9bb5n).ant-picker-dropdown .ant-picker-quarter-panel,
    :where(.css-dev-only-do-not-override-j9bb5n).ant-picker-dropdown .ant-picker-month-panel,
    :where(.css-dev-only-do-not-override-j9bb5n).ant-picker-dropdown .ant-picker-week-panel,
    :where(.css-dev-only-do-not-override-j9bb5n).ant-picker-dropdown .ant-picker-date-panel,
    :where(.css-dev-only-do-not-override-j9bb5n).ant-picker-dropdown .ant-picker-time-panel {
        display: flex;
        flex-direction: column;
        width: 187px !important;
    }

    .newReturnSingleBox:where(.css-j9bb5n).ant-picker-dropdown .ant-picker-decade-panel,
    :where(.css-j9bb5n).ant-picker-dropdown .ant-picker-year-panel,
    :where(.css-j9bb5n).ant-picker-dropdown .ant-picker-quarter-panel,
    :where(.css-j9bb5n).ant-picker-dropdown .ant-picker-month-panel,
    :where(.css-j9bb5n).ant-picker-dropdown .ant-picker-week-panel,
    :where(.css-j9bb5n).ant-picker-dropdown .ant-picker-date-panel,
    :where(.css-j9bb5n).ant-picker-dropdown .ant-picker-time-panel {
        display: flex;
        flex-direction: column;
        width: 187px !important;
    }

}