@import "../../../mixins.scss";

.seatdiv {
  border-radius: 8px;
}
.singlebusMain {
  border: 1px solid lightgray;
  margin-bottom: 12px;
  border-radius: 8px;

  .busNameHeading {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    padding: 10px 0px 10px 16px;
    border-radius: 8px 8px 0px 0px;
    background-image: linear-gradient(to right, var(--lightPurple), white);

    @include md {
      font-size: 16px;
    }
  }
  .spansborder {
    transform: "scaleY(0.3)";
  }
  .singleBusResultBox {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0px 0px 10px 10px;
    gap: 10px;
    justify-content: center;
    align-items: flex-start;
    padding: 5px 16px 16px 16px;
    position: relative;

    .sbsb1 {
      display: flex;
      flex-direction: column;
      gap: 8px;

      h3 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .sbsb2 {
      display: flex;
      flex-direction: row;
      gap: 8px;
      width: 100%;

      button {
        //background-color: var(--primary) !important;
        color: var(--pureWhite);
        //border: 1px solid var(--primary) !important;
        font-size: 12px !important;
        padding: 4px 6px !important;

        @include md {
          font-size: 16px !important;
          padding: 4px 15px !important;
        }

        &:hover {
          //background-color: var(--primaryHover) !important;
          color: var(--pureWhite) !important;
        }
      }

      h3 {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 0;

        @include md {
          font-size: 16px;
        }
      }
      h4 {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 0;

        @include md {
          font-size: 16px;
        }
      }
      p {
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 400;

        @include md {
          font-size: 14px;
        }
      }

      > div {
        flex: 1;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          display: flex;
          flex-direction: column;
          gap: 6px;
          justify-content: center;
          align-items: flex-start;
        }

        //   &:nth-child(1),
        //   &:nth-child(2),
        //   &:nth-child(3) {
        //     display: flex;
        //     flex-direction: column;
        //     gap: 6px;
        //     justify-content: center;
        //     align-items: center;
        //   }

        &:nth-child(4) {
          display: flex;
          flex-direction: column;
          gap: 6px;
          justify-content: space-between;
          align-items: flex-end; /* Aligns the fourth child to the end */
        }

        .busDistance {
          position: relative;
          height: 8px;
          width: 60px;

          @include md {
            width: 80px;
          }

          .busCircle1 {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: var(--darkPurple);
            border-radius: 100%;
            left: 0;
          }
          .busLine2 {
            content: "";
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: var(--darkPurple);
            //   border-radius: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          .busCircle2 {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: var(--darkPurple);
            border-radius: 100%;
            right: 0;
          }
        }
      }
    }
  }
}

.layOutParent {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  overflow-x: scroll;

  @include md {
    overflow: unset;
    overflow-x: unset;
  }
}

.busSeatAndPrice {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center !important;
  gap: 20px !important;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .origin {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .ant-select {
        min-width: 132px !important;
      }
    }
    &:nth-child(2) {
      width: 100%;
    }
  }

  .priceAndSeatInner {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .busSeats {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      p {
        font-weight: 500;
        font-size: 13px;

        @include md {
          font-size: 14px;
        }
      }
      span {
        font-weight: 500;
        font-size: 13px;

        @include md {
          font-size: 14px;
        }
      }
    }

    .totPriceAndContinue {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div {
        display: flex;
        gap: 10px;
        align-items: center;

        p {
          font-weight: 500;
          font-size: 13px;

          @include md {
            font-size: 14px;
          }
        }
        h2 {
          font-weight: 600;
          font-size: 13px;
          margin-bottom: 0;

          @include md {
            font-size: 16px;
          }
        }

        button {
          //background-color: var(--primary) !important;
          color: var(--pureWhite);
          //border: 1px solid var(--primary) !important;
          font-size: 12px !important;
          padding: 4px 6px !important;

          @include md {
            font-size: 16px !important;
            padding: 4px 15px !important;
          }

          &:hover {
            //background-color: var(--primaryHover) !important;
            color: var(--pureWhite) !important;
          }
        }
      }
    }
  }
}

.pickDrop {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 16px;
  border-radius: 10px;
  background-color: var(--lightPurple);

  > div {
    display: flex;
    flex-direction: column;
    // align-items: center;
    text-align: center;
    gap: 10px;
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    h3 {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 0;

      @include md {
        font-size: 15px;
      }
    }
    p {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 0;
      width: 100%;

      @include md {
        font-size: 15px;
      }
    }
    span {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 0;

      @include md {
        font-size: 15px;
      }
    }
  }
}

.initialBookingData {
  padding: 15px;
  border-radius: 8px;
  // border: 1px solid lightgray;
  border: 1px solid lightgray;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  @include md {
    max-height: 100vh;
    overflow-y: scroll;
    padding: 24px;
    position: sticky;
    position: -webkit-sticky;
    top: 10px;
  }

  hr {
    opacity: 0.2 !important;
  }

  .checkInCheckOutBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 8px;

    .checkInInnerBoxOne {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      > div {
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid lightgray;
        display: flex;
        flex-direction: column;
        gap: 6px;
        justify-content: flex-start;
        align-items: flex-start;

        &:nth-child(1) {
          border-right: 1px solid lightgray;
        }
      }

      p {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0 !important;
        color: var(--black4) !important;
        font-family: "Montserrat";
      }
      h5 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0 !important;
        color: var(--black2) !important;
        font-family: "Montserrat";
      }
      h2 {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0 !important;
        color: var(--black4) !important;
        font-family: "Montserrat";
      }
    }
    .checkInInnerBoxTwo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;

      p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0 !important;
        color: var(--black4) !important;
        font-family: "Montserrat";
      }
      h5 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0 !important;
        color: var(--black4) !important;
        font-family: "Montserrat";
      }
    }
  }

  .sideBarPriceBox {
    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
      color: var(--black4);

      p {
        font-weight: 600;
        color: var(--black4) !important;
        font-family: "Montserrat";
        font-size: 13px;
        margin-bottom: 0;
      }
      h6 {
        margin-bottom: 0;
        font-size: 13px;
        font-family: "Montserrat";
        color: var(--black4) !important;
      }
    }
  }

  .sideBarButtonsHotel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .showonBig {
      display: none;

      @include md {
        display: flex;
      }
    }

    // button {
    //   margin-top: 15px;
    //   width: 100%;
    //   padding: 15px 0px !important;
    //   background-color: #4F46E5 !important;
    //   color: var(--pureWhite) !important;
    //   font-size: 14px !important;
    //   font-family: "Montserrat";
    //   font-weight: 500;

    //   &:hover {
    //     background-color: var(--primaryHover) !important;
    //     color: var(--pureWhite) !important;
    //   }
    // }
  }
}

.passengerDetailsMainOuter {
  border: 1px solid lightgray;
  border-radius: 10px;
  background-color: white;
  h3 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    padding: 10px 0px 10px 16px;
    border-radius: 8px 8px 0px 0px;
    background-image: linear-gradient(to right, var(--lightPurple), white);

    @include md {
      font-size: 16px;
      margin-bottom: 12px;
      font-weight: 500;
    }
  }
  .passengerDetailsMain {
    padding: 5px 15px 15px 15px;

    border-radius: 10px;
    .bookFlightPassInner {
      input {
        border: 1px solid lightgray !important;
      }

      .ant-select {
        height: 38px !important;
      }
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 12px;

      @include md {
        font-size: 16px;
        margin-bottom: 12px;
        font-weight: 500;
      }
    }
    p {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 12px !important;

      @include md {
        font-size: 16px;
        // margin-bottom: 12px;
        font-weight: 500;
      }
    }
    label {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 4px !important;

      @include md {
        font-size: 14px;
        // margin-bottom: 12px;
        font-weight: 500;
      }
    }
  }
}

.passHeading {
  h3 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    padding: 10px 16px;
    width: 100%;
    // background-color: var(--lightPurple);
    background-image: linear-gradient(to right, var(--lightPurple), white);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;

    @include md {
      font-size: 18px;
    }
  }
}

.selectedPassDetails {
  padding: 16px;
  border-bottom: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-radius: 0 0 10px 10px;
  background-color: white;

  .selectedPassInside {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    @include md {
      gap: 25px;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 6px;

      p {
        font-size: 13px;
        font-weight: 500;

        @include md {
          font-size: 14px;
          font-weight: 500;
        }
      }
      span {
        font-size: 13px;
        font-weight: 500;

        @include md {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }
}

.busCancellation {
  border: 1px solid lightgray;
  border-radius: 10px;
  background-color: white;
  table {
    width: 100%;
    text-align: center;
    border-radius: 10px;

    thead {
      background-color: var(--lightPurple);
      padding: 10px !important;
      border-radius: 10px;
      tr {
        border-radius: 10px;
      }
      th {
        padding: 10px;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
        // padding: 10px 16px;
        // width: 100%;
        background-color: var(--lightPurple);

        &:nth-child(1) {
          border-top-left-radius: 10px;
        }
        &:nth-child(2) {
          border-top-right-radius: 10px;
        }

        @include md {
          font-size: 18px;
        }
      }
    }
    tbody {
      border-radius: 10px;

      td {
        padding: 7px;
      }
      tr:not(:last-child) {
        border-bottom: 1px solid lightgray;
      }
    }
  }
}

.busBook {
  display: flex;
  justify-content: center;
  button {
    // background-color: var(--primary) !important;
    color: var(--pureWhite);
    //border: 1px solid var(--primary) !important;
    font-size: 12px !important;
    padding: 4px 15px !important;

    @include md {
      font-size: 17px !important;
      padding: 4px 25px !important;
    }

    &:hover {
      // background-color: var(--primaryHover) !important;
      color: var(--pureWhite) !important;
    }
  }
}

.loadingSeat {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 180px;
  }
}
