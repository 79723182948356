.oflineContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
}
.oflineSvgContainer {
  width: 200px;
}
.oflineSvgContainer svg {
  width: 200px;
  animation: scaleAnimation 2s infinite alternate;
}
.oflineH1 {
  color: #4a4a4a;
  font-size: 22px;
  font-weight: 800;
  text-align: center;
}
.oflinep {
  color: #9b9b9b;
  font-size: 16px;
  text-align: center;
}
@keyframes scaleAnimation {
  0% {
    transform: scale(1); /* Initial scale */
  }
  100% {
    transform: scale(1.08); /* Final scale */
  }
}
