@import "../../../mixins.scss";

.HotelSelectRoomSelect {
  margin-bottom: 50px;

  .contentWrapper {
    position: relative;
  }

  .carouselTitle {
    font-size: 24px;
    color: white;
    margin-bottom: 20px;
    font-weight: normal;
  }

  .arrow {
    font-size: 30px;
    color: black;
    position: absolute;
    top: 44%;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.5;
    z-index: 1;
    display: none;

    @include md {
      display: block;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .carouselLeftNav {
    left: 30px;
  }

  .carouselRighttNav {
    right: 30px;
  }

  .loadingHotelContent {
    display: flex;
    gap: 10px;
    overflow-y: hidden;
    margin-right: -20px;
    margin-left: -20px;
    padding: 0 20px;

    @include md {
      gap: 20px;
      overflow: hidden;
      margin: 0;
      padding: 0;
    }

    .mainCOntent {
      //   width: 125px;
      width: 100%;

      @include md {
        width: 100%;
      }

      @include lg {
        width: 100%;
        // width: calc(25% - 16px);
      }

      flex-shrink: 0;

      .posterBlock {
        border-radius: 12px;
        width: 100%;
        aspect-ratio: 2 / 1;
        margin-bottom: 30px;
      }

      .textBlock {
        display: flex;
        flex-direction: column;

        .title {
          width: 100%;
          height: 20px;
          margin-bottom: 10px;
        }

        .date {
          width: 75%;
          height: 20px;
        }
      }
    }
  }
}

.HotelSelectRoomSelect2 {
  margin-bottom: 50px;

  .contentWrapper {
    position: relative;
  }

  .carouselTitle {
    font-size: 24px;
    color: white;
    margin-bottom: 20px;
    font-weight: normal;
  }

  .arrow {
    font-size: 30px;
    color: black;
    position: absolute;
    top: 44%;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.5;
    z-index: 1;
    display: none;

    @include md {
      display: block;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .carouselLeftNav {
    left: 30px;
  }

  .carouselRighttNav {
    right: 30px;
  }

  .loadingHotelContent2 {
    display: flex;
    gap: 10px;
    overflow-y: hidden;
    margin-right: -20px;
    margin-left: -20px;
    padding: 0 20px;

    @include md {
      gap: 20px;
      overflow: hidden;
      margin: 0;
      padding: 0;
    }

    .mainCOntent2 {
      //   width: 125px;
      width: 100%;

      @include md {
        width: 100%;
      }

      @include lg {
        width: 100%;
        // width: calc(25% - 16px);
      }

      flex-shrink: 0;

      .posterBlock {
        border-radius: 12px;
        width: 100%;
        aspect-ratio: 1 / 1.5;
        margin-bottom: 30px;
      }

      .textBlock {
        display: flex;
        flex-direction: column;

        .title {
          width: 100%;
          height: 20px;
          margin-bottom: 10px;
        }

        .date {
          width: 75%;
          height: 20px;
        }
      }
    }
  }
}
