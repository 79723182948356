@import "../../mixins.scss";

.bookingReviewMain {
  color: var(--black4) !important;
  font-family: "Montserrat";

  .roomNameAndCategoryOuter {
    border: 1px solid lightgray;
    border-radius: 8px;
    // padding: 10px 0px 10px 16px;

    @include md {
      border-radius: 12px;
      // padding: 20px 0px 20px 20px;
    }
    .hotelNameHeading {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
      padding: 10px 0px 10px 16px;
      border-radius: 10px 10px 0px 0px;
      background-image: linear-gradient(to right, var(--lightPurple), white);

      @include md {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .roomNameAndCategory {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 15px;
      padding: 10px;
      background-color: white;
      // border: 1px solid lightgray;
      border-radius: 5px;

      > div {
        width: 100%;
      }

      .hotelReviewAmenity {
        > div {
          display: grid;
          grid-template-columns: 50% 50%;
          justify-content: space-between;
          row-gap: 21px;

          p {
            font-size: 15px;
            font-family: "Montserrat";
            font-weight: 500;

            @include md {
              font-size: 16px;
            }

            i {
              margin-right: 5px;
            }
            svg {
              margin-right: 5px;
            }
          }
        }
      }

      @include md {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 22px;
        border-radius: 12px;
        padding: 20px;
        // margin-top: 1.5rem;
      }

      .roomTitle {
        .HotelRoomHeadings1 {
          font-weight: 600;
          font-size: 0.9rem;
          position: relative;
          margin-bottom: 15px;
          width: 94%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include md {
            font-size: 1.2rem;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .HotelRoomHeadings2 {
          font-weight: 600;
          font-size: 0.9rem;
          padding-bottom: 15px;
          position: relative;
          margin-bottom: 15px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include md {
            font-size: 1.2rem;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            width: 80px;
            height: 2px;
            background-color: #ddd;
          }
        }
      }

      h5 {
        font-size: 1.1rem;
        font-family: "Montserrat";
        font-weight: 600;
        margin-bottom: 0;

        @include md {
          font-size: 1.45rem;
        }
      }

      p {
        font-size: 14px;
        color: var(--black4) !important;
        font-family: "Montserrat";

        @include md {
          font-size: 15px;
        }
      }

      .hotelBookDesignFirst {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        gap: 30px;
        align-items: center;

        @include md {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          width: 100%;
          gap: 30px;
          align-items: center;
        }

        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;

          a {
            color: var(--black4);
            font-size: 14px;
            font-family: "Montserrat";
            text-decoration: none;
          }
        }
      }

      .roomDetailsIconBox {
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        justify-content: flex-start;

        > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;

          &:not(:last-child) {
            content: "|";
            padding-right: 12px;
          }
        }
      }

      .showMoore {
        color: var(--primary);
        cursor: pointer;
        font-weight: 600;
      }

      .error-text {
        font-size: 13px;
      }

      .passengerDetailsGuestBOx {
        border: 1px solid #ddd;
        column-gap: 20px;
        padding: 12px 15px;

        > div {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;
          justify-content: flex-start;

          p {
            font-size: 14px;
            color: var(--black1) !important;
            font-family: "Montserrat";
            font-weight: 500;

            @include md {
              font-size: 15px;
            }
          }
          span {
            font-size: 14px;
            color: var(--black4) !important;
            font-weight: 500;
            font-family: "Montserrat";

            @include md {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  .roomDetailsDangerousHtml {
    // padding: 16px;
    // border-radius: 8px;
    background: #fff;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    gap: 15px;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 5px;

    @include md {
      // display: flex;
      // flex-direction: column;
      // justify-content: flex-start;
      // align-items: flex-start;
      gap: 22px;
      border-radius: 12px;
      padding: 30px;
      // margin-top: 1.5rem;
    }

    .dangerousHTMLhotelDetails {
      font-size: 14px;
      color: var(--black4) !important;

      p {
        margin-bottom: 10px !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 1.9 !important;

        @include md {
          font-size: 15px !important;
        }
      }

      b {
        display: block !important;
        font-weight: 600 !important;
        margin-bottom: 10px;
      }

      br {
        display: none !important;
      }
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary) !important;
  }

  .ant-tabs .ant-tabs-tab-btn:hover {
    color: var(--primary) !important;
  }

  .imgGallery {
    position: relative;
    width: 100%;
    aspect-ratio: 1.2/1;
    background-size: cover;
    background-position: center;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px;

    // position: absolute;

    @include md {
      aspect-ratio: 1.2/1.2;
    }

    .lazy-load-image-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      overflow: hidden;
      // background: #000;
      // padding: 4px;
      // box-shadow: rgba(0, 0, 0, 0.4) 0px 27px 23px -1px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 12px;
      }
    }

    .circleRating {
      width: 40px;
      height: 40px;
      position: relative;
      top: 30px;
      background-color: white;
      flex-shrink: 0;

      @include md {
        width: 50px;
        height: 50px;
      }
    }

    .genres {
      display: none;
      position: relative;

      @include md {
        display: flex;
        flex-flow: wrap;
        justify-content: flex-end;
      }
    }
  }

  .initialBookingData {
    padding: 15px;
    border-radius: 8px;
    // border: 1px solid lightgray;
    background-color: white;
    border: 1px solid lightgray;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    @include md {
      max-height: 100vh;
      overflow-y: scroll;
      padding: 24px;
      position: sticky;
      position: -webkit-sticky;
      top: 10px;
    }

    hr {
      opacity: 0.2 !important;
    }

    .checkInCheckOutBox {
      display: flex;
      flex-direction: column;
      width: 100%;
      border: 1px solid lightgray;
      border-radius: 8px;

      .checkInInnerBoxOne {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        > div {
          width: 100%;
          padding: 10px;
          border-bottom: 1px solid lightgray;
          display: flex;
          flex-direction: column;
          gap: 6px;
          justify-content: flex-start;
          align-items: flex-start;

          &:nth-child(1) {
            border-right: 1px solid lightgray;
          }
        }

        p {
          font-size: 13px;
          font-weight: 500;
          margin-bottom: 0 !important;
          color: var(--black4) !important;
          font-family: "Montserrat";
        }
        h5 {
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 0 !important;
          color: var(--black2) !important;
          font-family: "Montserrat";
        }
        h2 {
          font-size: 13px;
          font-weight: 500;
          margin-bottom: 0 !important;
          color: var(--black4) !important;
          font-family: "Montserrat";
        }
      }
      .checkInInnerBoxTwo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;

        p {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0 !important;
          color: var(--black4) !important;
          font-family: "Montserrat";
        }
        h5 {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0 !important;
          color: var(--black4) !important;
          font-family: "Montserrat";
        }
      }
    }

    .sideBarPriceBox {
      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
        color: var(--black4);

        p {
          font-weight: 600;
          color: var(--black4) !important;
          font-family: "Montserrat";
          font-size: 13px;
          margin-bottom: 0;
        }
        h6 {
          margin-bottom: 0;
          font-size: 13px;
          font-family: "Montserrat";
          color: var(--black4) !important;
        }

        .grTotal {
          font-weight: 600;
          color: var(--black2) !important;
          font-family: "Montserrat";
          font-size: 15px;
          margin-bottom: 0;

          @include md {
            font-size: 17px;
          }
        }
      }
    }

    .sideBarButtonsHotel {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .showonBig {
        display: none;

        @include md {
          display: flex;
        }
      }

      button {
        margin-top: 15px;
        width: 100%;
        padding: 21px 0px !important;
        background-color: var(--primary) !important;
        color: var(--pureWhite) !important;
        font-size: 13px !important;
        font-family: "Montserrat";
        font-weight: 500;

        &:hover {
          background-color: var(--primaryHover) !important;
          color: var(--pureWhite) !important;
        }
      }
    }
  }

  .roomComponent {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 15px;
    width: 100%;

    .roomCompCardInner {
      width: 100%;
      height: auto;
      background: #fff 0% 0% no-repeat padding-box;
      box-shadow: rgba(9, 3, 4, 0.1) 0px 4px 24px;
      border-radius: 6px;
      opacity: 1;
      margin: 7px 0px 7px 0px !important;
      border: 0.5px solid transparent;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 15px;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;

      @include md {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 15px;
        align-items: center;
        padding-left: 10px;
        padding-right: 0px;
      }

      > div {
        width: 100%;

        // @include md {
        //   width: unset;
        // }
      }

      .insideRoomComp {
        display: flex;
        align-items: center;
        gap: 10px;

        .hotelTitleComp {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          gap: 10px;
          padding: 12px;

          p {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }

      .othInc {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;

        .othIncInner {
          padding: 4px 0px;

          .panDesign {
            font-weight: 500;
            color: #071c2c;
            font-size: 12px;
          }
        }
      }

      .priceCheck {
        width: 100%;
        border-left: unset;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        gap: 15px;

        @include md {
          min-width: 200px;
          border-left: 1px solid lightgray;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 15px;
        }

        h3 {
          padding: 8px 16px;
          border-radius: 32px;
          color: #d90429;
          font-family: "Montserrat";
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          cursor: pointer;
        }

        .price {
          font-weight: 700;
          font-size: 20px;
          color: #21325d;
        }
      }
    }
  }

  .HotelRoomHeadings {
    font-weight: 600;
    font-size: 1.1rem;
    padding-bottom: 15px;
    position: relative;
    margin-bottom: 15px;

    // @include ellipsis(1);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include md {
      font-size: 1.45rem;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      width: 80px;
      height: 2px;
      background-color: #ddd;
    }
  }
}
