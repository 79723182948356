.form-container-inventory {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* background-image: url("./formbg.jpeg");

  background-size: cover;

  background-position: center;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px); */

  background:#F9FAFD;
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* background-image: url("https://skytrails.s3.amazonaws.com/random/hotelInventory.jpg"); */
  /* opacity:0.3; */
  
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-heading {
  margin-bottom: 20px;
  text-align: center;
}

.form-item {
  width: 100%;
  margin-bottom: 16px;
}

.add-facility-button {
  width: 100%;
  margin-bottom: 16px;
}

.upload-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.submit-button {
  width: 100%;
}

.custom-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.success-message {
  text-align: center;
}

.inventoryForm {
  margin-top: 10px;
}

/* .inventoryForm {
  background-image: url("./bg.png");
} */

.form-item {
  /* Add any necessary styles for the form item */
}

.add-facility-button {
  /* Add styles for the button if needed */
}

.facility-chips {
  display: flex;
  flex-wrap: wrap; /* Ensures that tags wrap to the next line */
  gap: 8px; /* Space between tags */
  margin-top: 10px;
}

.facility-chips .ant-tag {
  margin-bottom: 8px; /* Space between lines of tags */
}

@media (max-width: 768px) {
  .facility-chips {
    gap: 4px; /* Reduce space between tags on smaller screens */
  }

  .add-facility-button {
    width: 100%; /* Ensure the button spans full width on small screens */
    margin-bottom: 10px;
  }

  .form-item {
    flex-direction: column;
    align-items: stretch;
  }
}

.addItems {
  margin-top: 5px;
}

/* In YourComponent.css or inline styles */
.room-details-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center; /* Center the heading if desired */
  color: #333; /* Adjust color as needed */
}
