@import "../../mixins.scss";

.HoliCateHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  text-transform: capitalize;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    h5 {
      margin-bottom: 0 !important;
    }
  }

  p {
    margin-bottom: 0 !important;
    font-weight: 600;
  }
  img {
    width: 30px;
    // margin-right: 8px;
  }
}

.paddHotCatTopRoute {
  padding: 40px;
  border-radius: 10px;
  // margin-bottom: 20px;
  overflow-x: hidden;

  > h2 {
    font-size: 20px !important;
    font-weight: 700 !important;
    text-align: center !important;
    margin-bottom: 33px !important;

    @include md {
      font-size: 35px;
    }
  }
}

.carouselTopFlight {
  margin-bottom: 20px;

  .contentWrapper {
    position: relative;
  }

  .carouselTitle {
    font-size: 24px;
    color: white;
    margin-bottom: 20px;
    font-weight: normal;
  }

  .arrow {
    font-size: 30px;
    color: black;
    position: absolute;
    top: 44%;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.5;
    z-index: 1;
    display: none;

    @include md {
      display: block;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .carouselLeftNav {
    left: 30px;
  }

  .carouselRighttNav {
    right: 30px;
  }

  .loadingSkeleton {
    display: flex;
    gap: 10px;
    overflow-y: hidden;
    margin-right: -20px;
    margin-left: -20px;
    padding: 0 20px;

    @include md {
      gap: 20px;
      overflow: hidden;
      margin: 0;
      padding: 0;
    }

    .skeletonItem {
      width: 125px;

      @include md {
        width: calc(25% - 15px);
      }

      @include lg {
        width: calc(20% - 16px);
      }

      flex-shrink: 0;

      .posterBlock {
        border-radius: 12px;
        width: 100%;
        aspect-ratio: 1 / 1.5;
        margin-bottom: 30px;
      }

      .textBlock {
        display: flex;
        flex-direction: column;

        .title {
          width: 100%;
          height: 20px;
          margin-bottom: 10px;
        }

        .date {
          width: 75%;
          height: 20px;
        }
      }
    }
  }

  .carouselItems {
    display: flex;
    gap: 10px;
    overflow-y: hidden;
    margin-right: -20px;
    margin-left: -20px;
    padding: 0 20px;

    @include md {
      gap: 20px;
      overflow: hidden;
      margin: 0;
      padding: 0;
    }

    .carouselItem {
      width: 150px;
      cursor: pointer;

      @include md {
        width: calc(25% - 15px);
      }

      @include lg {
        width: calc(20% - 16px);
      }

      flex-shrink: 0;

      .posterBlock {
        position: relative;
        width: 100%;
        aspect-ratio: 1.8/1.8;
        background-size: cover;
        background-position: center;
        margin-bottom: 10px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 10px;

        @include md {
          aspect-ratio: 1.5/1.5;
        }

        .lazy-load-image-background {
          position: absolute;
          // top: 0;
          // left: 0;
          top: 50%;
          left: 50%;
          transform: translate(-43%, -50%);
          width: 100%;
          height: 100%;
          border-radius: 12px;
          overflow: hidden;
          // background: #000;
          // padding: 4px;
          // box-shadow: rgba(0, 0, 0, 0.4) 0px 27px 23px -1px;

          img {
            width: 85%;
            height: 85%;
            object-fit: cover;
            object-position: center;
            border-radius: 12px;
          }
        }

        .circleRating {
          width: 40px;
          height: 40px;
          position: relative;
          top: 30px;
          background-color: white;
          flex-shrink: 0;

          @include md {
            width: 50px;
            height: 50px;
          }
        }

        .genres {
          display: none;
          position: relative;

          @include md {
            display: flex;
            flex-flow: wrap;
            justify-content: flex-end;
          }
        }
      }

      .textBlock {
        color: #071c2c;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;

        .titleHoliCat {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 10px;
          line-height: 24px;
          display: flex !important;
          gap: 4px;
          @include ellipsis(1);

          @include md {
            font-size: 15px;
          }
        }

        .dateHoliCat {
          font-size: 14px;
          opacity: 0.8;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #e73c34;
        }
      }
    }
  }
}
